import { useState, useEffect } from 'react';
import Topbar from './Topbar';
import Footer from './Footer';
import { Stack, 
    VStack, 
    Box, 
    Heading,
    Text,
    Textarea,
    Button,
} from '@chakra-ui/react';
import Head from '../functionalComponents/Head';
import axios from 'axios';
import { SymbolHankaku2Zenkaku } from '../functionalComponents/Helper';

export default function Furiganakun(){
    const [text, setText] = useState('');
    const [textWithFurigana, setTextWithFurigana] = useState('');

    useEffect(()=>{
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, 200);
    }, [])

    function furigana(text){
        axios.get(`${process.env.REACT_APP_API_URL}/api/furigana/${encodeURIComponent(text.replace(/\n\n+/g, '\n\n').replace(/\n/g, '＠＃＄＠'))}`)
            .then(res => {
                if(res.data.status === 'OK'){
                    setTextWithFurigana(res.data.with_furigana.join('').replace(/＠＃＄＠/g, '<br/>').replace(/@#\$@/g, ''));
                }
            });
    }
  
    return (
        <>
            <Head 
                url={window.location.href} 
                title='Furigana-kun (ふりがな君) ー Tool for adding furigana automatically to Japanese text 自動でふりがなツール by LangLabJP' 
                description='Furigana-kun (ふりがな君) ー Tool for adding furigana automatically to Japanese text 自動でふりがなツール by LangLabJP' 
            />
            <Topbar />
            <VStack m='5px' spacing='8px'>
                <Box w='1100px' maxW='100%'>
                    <Heading as='h1' size={'md'} color={'blue.700'} pl={1} my={2} noOfLines={1}>
                        Furigana-kun ふりがな君
                    </Heading>
                </Box>
                <Box w='1100px' maxW='100%'>
                    <Stack direction={['column', 'row']} spacing='12px' maxW='100%' pl={1}>
                        <Box w='750%' maxW='100%'>
                            <Text my={1}>Adding furigana automatically to Japanese text.</Text>
                            <Text as='p' fontWeight={700} mt={4}>① Paste the text into the teatarea, and then click the button below.</Text>
                            <Textarea
                                value={text}
                                onChange={(e) => {
                                    setText(SymbolHankaku2Zenkaku(e.target.value))
                                }}
                                placeholder='Japanese text here...'
                                size='lg'
                                minH={'230px'}
                                borderWidth='1.5px'
                                borderColor='blue.200'
                                borderRadius='10px'
                                my={1}
                            />
                            <Button 
                                colorScheme='blue'
                                bg='blue.400'
                                color='white'
                                _hover={{
                                    bg: 'blue.500',
                                }}
                                disabled={
                                    text.length > 1000 ? true : false
                                }
                                onClick={() => {
                                    furigana(text)
                                }}
                                my={1}
                            >
                                ふりがなふってくれ
                            </Button>
                            <Button 
                                colorScheme='pink'
                                bg='pink.300'
                                color='white'
                                _hover={{
                                    bg: 'pink.400',
                                }}
                                onClick={() => {
                                    setText('')
                                    setTextWithFurigana('')
                                }}
                                my={1}
                                ml={2}
                            >
                                Clear all
                            </Button>
                            <Text as='span' color={text.length > 1000 ? 'red' : ''}>　{text.length+' / 1000 characters'}</Text>
                            <Text as='p' fontWeight={700} mt={8}>② Check the text with furigana below.</Text>
                            <Box 
                                minH={'150px'}
                                mt={4}
                                mb={'80px'}
                                fontSize={'xl'}
                                lineHeight={2.5} 
                                dangerouslySetInnerHTML={{__html: textWithFurigana}}
                            ></Box>
                        </Box>
                        <Box w='250%' maxW='100%'></Box>
                    </Stack>
                </Box>
            </VStack>
            <Footer />
        </>
    )
}