import { 
    useState, 
    useEffect 
} from 'react';
import { 
    VStack, 
    Center,
    Spinner,
    Box, 
    Heading, 
    Text,
    Highlight,
    Divider
} from '@chakra-ui/react';
import axios from 'axios';
import { Link } from '../functionalComponents/ChakraLinkAsReactRouter';

function WordCard({ query, headLemma, headReading, lemmas, meanings, rarelyUsedForms }) {
    return (
        <Box p={5} mt={3} w='100%' shadow='md' borderWidth='1px'>
            <Heading as='h1' fontSize='2xl' pl={1}>{headLemma}</Heading>
            <Heading as='h1' fontSize='md' mt={1}>{headReading}</Heading>
            {meanings.map((meaning, index) => (
                <Box mt={4} key={index}>
                    <Heading fontSize='md' color={'blue.300'}>{meaning.pos.join(', ')}</Heading>
                    <Text as='span' fontSize='sm'>{index+1}.　</Text>
                    <Text as='b' fontSize='md'>{meaning.gloss.join('; ')}</Text>
                    <Text as='span' fontSize='sm' display={
                        meaning.restrictedto || meaning.related || meaning.antonym || meaning.field || meaning.misc || meaning.dial || meaning.additionalInfo ? '' : 'none'
                    }> ― </Text>
                    {meaning.restrictedto ? (
                        <Text as='span' fontSize='sm'>{'restricted to the form(s): ' + meaning.restrictedto.join(', ') + '; ' }</Text>
                    ) : ''}
                    {meaning.related ? (
                        <Text as='span' fontSize='sm'>see also:{meaning.related.map((word, index) => (
                            <Link to={`/Jedict/${word.split('/')[0]}`} key={index} color={'blue.600'} onClick={() => {
                                if(word.split('/')[0] === query) window.scrollTo({ top: 0, behavior: 'smooth' })
                            }}>
                                &nbsp;{word}&nbsp;
                            </Link>
                        ))}; </Text>
                        // <Text as='span' fontSize='sm'>{'see also: ' + meaning.related.join(', ') + '; ' }</Text>
                    ) : ''}
                    {meaning.antonym ? (
                        <Text as='span' fontSize='sm'>antonym:{meaning.antonym.map((word, index) => (
                            <Link to={`/Jedict/${word.split('/')[0]}`} key={index} color={'blue.600'} onClick={() => {
                                if(word.split('/')[0] === query) window.scrollTo({ top: 0, behavior: 'smooth' })
                            }}>
                                &nbsp;{word}&nbsp;
                            </Link>
                        ))}; </Text>
                        // <Text as='span' fontSize='sm'>{'antonym: ' + meaning.antonym.join(', ') + '; ' }</Text>
                    ) : ''}

                    {meaning.additionalInfo ? (
                        <Text as='span' fontSize='sm'>{meaning.additionalInfo.join(', ') + '; ' }</Text>
                    ) : ''}
                    {meaning.dial ? (
                        <Text as='span' fontSize='sm'>{'dial: ' + meaning.dial.join(', ') + '; ' }</Text>
                    ) : ''}
                    {meaning.field ? (
                        <Text as='span' fontSize='sm'>{'field: ' + meaning.field.join(', ') + '; ' }</Text>
                    ) : ''}
                    {meaning.misc ? (
                        <Text as='span' fontSize='sm'>{meaning.misc.join(', ') + '; ' }</Text>
                    ) : ''}
                </Box>
            ))}

            <Divider mt={4} display={lemmas.length>1 || rarelyUsedForms ? '' : 'none'}/>
            {lemmas.length>1 ? (
                <>
                    <Box mt={4}>
                        <Heading as='h2' fontSize='md'>Other forms:</Heading>
                        {lemmas.map((lemma, index) => {
                            if(index === 0){
                                return <Box key={index}></Box>;
                            }
                            return (
                                <Box key={index}>
                                    <Text as='span' fontSize='md'>{lemma.replace(/\/(.+)/g, '【$1】')}</Text>
                                    <Text as='span' fontSize='md' display={index === lemmas.length-1 ? 'none' : ''}>、</Text>
                                </Box>
                            )
                        })}
                    </Box>
                </>
            ) : ''}
            {rarelyUsedForms ? (
                <>
                    <Box mt={4}>
                        <Heading as='h2' fontSize='md'>Rarely used forms or readings:</Heading>
                        {rarelyUsedForms.map((lemma, index) => (
                            <Box as='span' key={index}>
                                <Text as='span' fontSize='md'>{lemma.replace(/\/(.+)/g, '【$1】')}</Text>
                                <Text as='span' fontSize='md' display={index === rarelyUsedForms.length-1 ? 'none' : ''}>、</Text>
                            </Box>
                        ))}
                    </Box>
                </>
            ) : ''}
        </Box>
    );
}

export default function JedictWordsForKanji(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [words, setWords] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/api/getFreqWordsContainsKanji/${props.query}`)
            .then(res => {
                if(res.data.status === 'OK'){
                    setWords(res.data.dict_results.filter(word => word.lemmas[0].split('/')[0].includes(props.query)));
                    // console.log(res.data.dict_results);
                    setIsLoading(false);
                }
            });
    }, [props.query]);

    return (
        <VStack spacing={4}>
            {isLoading ? (
                <>
                    <Center>
                        <Spinner
                            thickness='1.5px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='sm'
                        />
                    </Center>
                </>
            ) : (
                <>
                    <Box w='100%' display={words.length ? '' : 'none'}>
                        {words.map((word, index) => (
                            <WordCard
                                key={index}
                                query={props.query}
                                headLemma={word.lemmas[0].split('/')[0]}
                                headReading={word.lemmas[0].split('/').length === 2 ? `【${word.lemmas[0].split('/')[1]}】` : ''}
                                lemmas={word.lemmas}
                                meanings={word.meanings}
                                rarelyUsedForms={word.rarelyUsedForms}
                            />
                        ))}
                    </Box>
                    <Box w='100%' display={words.length ? 'none' : ''}>
                        <Highlight query={['Knowledge', 'Sentence Breakdown', 'Related Searches']} styles={{ px: '1', py: '1', bg: 'orange.100' }}>
                            No matches found!
                        </Highlight>
                    </Box>
                </>
            )}
        </VStack>
    );
}