// Head.js
// https://zenn.dev/mgmgshake/articles/83d6865486c331

import * as React from 'react';
// react-helmetをimportするのはこのコンポーネントだけになります
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';

// ページコンポーネントから、titleをpropしています
export default function Head(props) {
  const { url, title, description } = props;
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta
          name='viewport'
          content='width=device-width,initial-scale=1.0,minimum-scale=1.0'
        ></meta>
        {/* OGPやフォントの記述も共通化するとGOOD! */}
        {/* OGP ここから */}
        <head prefix='og: http://ogp.me/ns#' />
        <meta property='og:url' content={url} />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:site_name' content='LangLabJP' />
        <meta property='og:image' content={`${url.split('/').splice(0, 3).join('/')}/ogp_image.png`} />
        {/* OGP ここまで */}
        {/* Google Font ここから */}
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
        <link
          href='https://fonts.googleapis.com/css?family=BIZ+UDMincho|New+Tegomin|Yuji+Mai|Zen+Kurenaido|Courgette'
          rel='stylesheet'
        />
        {/* Google Font ここまで */}
      </Helmet>
      {process.env.REACT_APP_API_URL === '' ?
        <GoogleAnalytics /> :  // GA only for production 
        ''
      }
    </>
  );
}