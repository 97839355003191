import { useEffect } from 'react';
import { Stack, 
    VStack, 
    Box, 
    /*Heading, */
    Text,
    List,
    ListItem,
    ListIcon
} from '@chakra-ui/react';
import Head from '../functionalComponents/Head';
import JedictSearchbox from './JedictSearchbox';
import { CheckIcon } from '@chakra-ui/icons'
import { Link } from '../functionalComponents/ChakraLinkAsReactRouter';


export default function JedictIndex(){
    useEffect(()=>{
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, 200);
    }, [])

    return (
        <>
            <Head 
                url={window.location.href} 
                title='Japanese-English Dictionary (和英辞書) by LangLabJP' 
                description='Japanese-English Dictionary (和英辞書) by LangLabJP' 
            />
            <VStack m='5px' spacing='24px'>
                <Box w='1100px' maxW='100%'>
                    <JedictSearchbox query='' />
                </Box>
                <Box w='1100px' maxW='100%'>
                    <Stack direction={['column', 'row']} spacing='12px' maxW='100%'>
                        <Box w='125%' maxW='100%'></Box>
                        <Box w='750%' maxW='100%'>
                            <Text>
                                <Text as='span' color={'blue.400'}>LangLab</Text><Text as='span' color={'red.400'}>JP</Text> Dictionaries are more than just a dictionary. 
                                Using this Japanese-English dictionary (和英辞書), you can not only look up a Japanese word, but also search for any knowledge about Japan.
                            </Text>
                            <Text my={6}>Here are some examples of how to use it.</Text>
                            <List spacing={4} mb={8}>
                                <ListItem>
                                    <ListIcon as={CheckIcon} />
                                    <Text as='b'>Word: </Text>
                                    <Link to={`/Jedict/学生`} color={'blue.600'}>
                                        学生
                                    </Link>、
                                    <Link to={`/Jedict/パソコン`} color={'blue.600'}>
                                        パソコン
                                    </Link>、
                                    <Link to={`/Jedict/野良猫`} color={'blue.600'}>
                                        野良猫
                                    </Link>
                                    <Text as='span' color={'gray.600'} fontSize={'sm'}> → Check <u>Words</u></Text>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} />
                                    <Text as='b'>Inflection: </Text>
                                    <Link to={`/Jedict/おいしかった`} color={'blue.600'}>
                                        おいしかった
                                    </Link>、
                                    <Link to={`/Jedict/壊れた`} color={'blue.600'}>
                                        壊れた
                                    </Link>、
                                    <Link to={`/Jedict/書いています`} color={'blue.600'}>
                                        書いています
                                    </Link>
                                    <Text as='span' color={'gray.600'} fontSize={'sm'}> → Check <u>Sentence Breakdown</u></Text>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} />
                                    <Text as='b'>Trendy word: </Text>
                                    <Link to={`/Jedict/コロナウイルス`} color={'blue.600'}>
                                        コロナウイルス
                                    </Link>、
                                    <Link to={`/Jedict/ビットコイン`} color={'blue.600'}>
                                        ビットコイン
                                    </Link>、
                                    <Link to={`/Jedict/親ガチャ`} color={'blue.600'}>
                                        親ガチャ
                                    </Link>
                                    <Text as='span' color={'gray.600'} fontSize={'sm'}> → Check <u>Words</u> / <u>Knowledge</u></Text>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} />
                                    <Text as='b'>Pharse / Sentence: </Text>
                                    <Link to={`/Jedict/私はバスで学校に通っている`} color={'blue.600'}>
                                        私はバスで学校に通っている
                                    </Link>
                                    <Text as='span' color={'gray.600'} fontSize={'sm'}> → Check <u>Sentence Breakdown</u></Text>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} />
                                    <Text as='b'>Idioms: </Text>
                                    <Link to={`/Jedict/虎穴に入らずんば虎子を得ず`} color={'blue.600'}>
                                        虎穴に入らずんば虎子を得ず
                                    </Link>
                                    <Text as='span' color={'gray.600'} fontSize={'sm'}> → Check <u>Words</u></Text>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} />
                                    <Text as='b'>Lyrics: </Text>
                                    <Link to={`/Jedict/地球と太陽みたいに光と影が生まれて`} color={'blue.600'}>
                                        地球と太陽みたいに光と影が生まれて
                                    </Link>
                                    <Text as='span' color={'gray.600'} fontSize={'sm'}> → Check <u>Sentence Breakdown</u></Text>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} />
                                    <Text as='b'>Famous person: </Text>
                                    <Link to={`/Jedict/安倍晋三`} color={'blue.600'}>
                                        安倍晋三
                                    </Link>、
                                    <Link to={`/Jedict/大谷翔平`} color={'blue.600'}>
                                        大谷翔平
                                    </Link>
                                    <Text as='span' color={'gray.600'} fontSize={'sm'}> → Check <u>Knowledge</u></Text>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} />
                                    <Text as='b'>Organization: </Text>
                                    <Link to={`/Jedict/捜査一課`} color={'blue.600'}>
                                        捜査一課
                                    </Link>、
                                    <Link to={`/Jedict/テレ東`} color={'blue.600'}>
                                        テレ東
                                    </Link>、
                                    <Link to={`/Jedict/すき家`} color={'blue.600'}>
                                        すき家
                                    </Link>
                                    <Text as='span' color={'gray.600'} fontSize={'sm'}> → Check <u>Knowledge</u></Text>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} />
                                    <Text as='b'>Place: </Text>
                                    <Link to={`/Jedict/秋田`} color={'blue.600'}>
                                        秋田
                                    </Link>、
                                    <Link to={`/Jedict/白石蔵王`} color={'blue.600'}>
                                        白石蔵王
                                    </Link>、
                                    <Link to={`/Jedict/奄美大島`} color={'blue.600'}>
                                        奄美大島
                                    </Link>
                                    <Text as='span' color={'gray.600'} fontSize={'sm'}> → Check <u>Knowledge</u> / <u>Words</u></Text>
                                </ListItem>
                            </List>
                            <Text my={6}>
                                And you can find words related to your keyword / sentence at&nbsp;
                                <Text as='span' color={'gray.600'} fontSize={'sm'}><u>Related Searches</u></Text>
                                <br/>
                                <Text as='span'>e.g.　</Text>
                                <Link to={`/Jedict/仙台の名物料理`} color={'blue.600'}>
                                    仙台の名物料理
                                </Link>、
                                <Link to={`/Jedict/愛媛%E3%80%80果物`} color={'blue.600'}>
                                    愛媛 果物
                                </Link>
                            </Text>
                        </Box>
                        <Box w='125%' maxW='100%'></Box>
                    </Stack>
                </Box>
            </VStack>
        </>
    )
}