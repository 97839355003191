export function KanjiData() {
    return [
        {"kanji":"亜", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"哀", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"挨", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"愛", "stroke":13, "grade":4, "jlpt":"N3"},
        {"kanji":"曖", "stroke":17, "grade":7, "jlpt":"-"},
        {"kanji":"悪", "stroke":11, "grade":3, "jlpt":"N4"},
        {"kanji":"握", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"圧", "stroke":5, "grade":5, "jlpt":"N2"},
        {"kanji":"扱", "stroke":6, "grade":7, "jlpt":"N2"},
        {"kanji":"宛", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"嵐", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"安", "stroke":6, "grade":3, "jlpt":"N5"},
        {"kanji":"案", "stroke":10, "grade":4, "jlpt":"N3"},
        {"kanji":"暗", "stroke":13, "grade":3, "jlpt":"N4"},
        {"kanji":"以", "stroke":5, "grade":4, "jlpt":"N4"},
        {"kanji":"衣", "stroke":6, "grade":4, "jlpt":"N3"},
        {"kanji":"位", "stroke":7, "grade":4, "jlpt":"N3"},
        {"kanji":"囲", "stroke":7, "grade":5, "jlpt":"N2"},
        {"kanji":"医", "stroke":7, "grade":3, "jlpt":"N4"},
        {"kanji":"依", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"委", "stroke":8, "grade":3, "jlpt":"N2"},
        {"kanji":"威", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"為", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"畏", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"胃", "stroke":9, "grade":6, "jlpt":"N2"},
        {"kanji":"尉", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"異", "stroke":11, "grade":6, "jlpt":"N2"},
        {"kanji":"移", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"萎", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"偉", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"椅", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"彙", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"意", "stroke":13, "grade":3, "jlpt":"N4"},
        {"kanji":"違", "stroke":13, "grade":7, "jlpt":"N3"},
        {"kanji":"維", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"慰", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"遺", "stroke":15, "grade":6, "jlpt":"N1"},
        {"kanji":"緯", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"域", "stroke":11, "grade":6, "jlpt":"N2"},
        {"kanji":"育", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"一", "stroke":1, "grade":1, "jlpt":"N5"},
        {"kanji":"壱", "stroke":7, "grade":7, "jlpt":"-"},
        {"kanji":"逸", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"茨", "stroke":9, "grade":4, "jlpt":"-"},
        {"kanji":"芋", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"引", "stroke":4, "grade":2, "jlpt":"N4"},
        {"kanji":"印", "stroke":6, "grade":4, "jlpt":"N2"},
        {"kanji":"因", "stroke":6, "grade":5, "jlpt":"N3"},
        {"kanji":"咽", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"姻", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"員", "stroke":10, "grade":3, "jlpt":"N4"},
        {"kanji":"院", "stroke":10, "grade":3, "jlpt":"N4"},
        {"kanji":"淫", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"陰", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"飲", "stroke":12, "grade":3, "jlpt":"N5"},
        {"kanji":"隠", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"韻", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"右", "stroke":5, "grade":1, "jlpt":"N5"},
        {"kanji":"宇", "stroke":6, "grade":6, "jlpt":"N2"},
        {"kanji":"羽", "stroke":6, "grade":2, "jlpt":"N2"},
        {"kanji":"雨", "stroke":8, "grade":1, "jlpt":"N5"},
        {"kanji":"唄", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"鬱", "stroke":29, "grade":7, "jlpt":"-"},
        {"kanji":"畝", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"浦", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"運", "stroke":12, "grade":3, "jlpt":"N4"},
        {"kanji":"雲", "stroke":12, "grade":2, "jlpt":"N3"},
        {"kanji":"永", "stroke":5, "grade":5, "jlpt":"N2"},
        {"kanji":"泳", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"英", "stroke":8, "grade":4, "jlpt":"N4"},
        {"kanji":"映", "stroke":9, "grade":6, "jlpt":"N4"},
        {"kanji":"栄", "stroke":9, "grade":4, "jlpt":"N2"},
        {"kanji":"営", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"詠", "stroke":12, "grade":7, "jlpt":"-"},
        {"kanji":"影", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"鋭", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"衛", "stroke":16, "grade":5, "jlpt":"N1"},
        {"kanji":"易", "stroke":8, "grade":5, "jlpt":"N3"},
        {"kanji":"疫", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"益", "stroke":10, "grade":5, "jlpt":"N2"},
        {"kanji":"液", "stroke":11, "grade":5, "jlpt":"N2"},
        {"kanji":"駅", "stroke":14, "grade":3, "jlpt":"N5"},
        {"kanji":"悦", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"越", "stroke":12, "grade":7, "jlpt":"N3"},
        {"kanji":"謁", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"閲", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"円", "stroke":4, "grade":1, "jlpt":"N5"},
        {"kanji":"延", "stroke":8, "grade":6, "jlpt":"N2"},
        {"kanji":"沿", "stroke":8, "grade":6, "jlpt":"N2"},
        {"kanji":"炎", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"怨", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"宴", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"媛", "stroke":12, "grade":4, "jlpt":"-"},
        {"kanji":"援", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"園", "stroke":13, "grade":2, "jlpt":"N4"},
        {"kanji":"煙", "stroke":13, "grade":7, "jlpt":"N3"},
        {"kanji":"猿", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"遠", "stroke":13, "grade":2, "jlpt":"N4"},
        {"kanji":"鉛", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"塩", "stroke":13, "grade":4, "jlpt":"N3"},
        {"kanji":"演", "stroke":14, "grade":5, "jlpt":"N2"},
        {"kanji":"縁", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"艶", "stroke":19, "grade":7, "jlpt":"-"},
        {"kanji":"汚", "stroke":6, "grade":7, "jlpt":"N3"},
        {"kanji":"王", "stroke":4, "grade":1, "jlpt":"N3"},
        {"kanji":"凹", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"央", "stroke":5, "grade":3, "jlpt":"N3"},
        {"kanji":"応", "stroke":7, "grade":5, "jlpt":"N2"},
        {"kanji":"往", "stroke":8, "grade":5, "jlpt":"N2"},
        {"kanji":"押", "stroke":8, "grade":7, "jlpt":"N4"},
        {"kanji":"旺", "stroke":8, "grade":7, "jlpt":"-"},
        {"kanji":"欧", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"殴", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"桜", "stroke":10, "grade":5, "jlpt":"N2"},
        {"kanji":"翁", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"奥", "stroke":12, "grade":7, "jlpt":"N4"},
        {"kanji":"横", "stroke":15, "grade":3, "jlpt":"N3"},
        {"kanji":"岡", "stroke":8, "grade":4, "jlpt":"-"},
        {"kanji":"屋", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"億", "stroke":15, "grade":4, "jlpt":"N2"},
        {"kanji":"憶", "stroke":16, "grade":7, "jlpt":"N2"},
        {"kanji":"臆", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"虞", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"乙", "stroke":1, "grade":7, "jlpt":"N1"},
        {"kanji":"俺", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"卸", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"音", "stroke":9, "grade":1, "jlpt":"N4"},
        {"kanji":"恩", "stroke":10, "grade":6, "jlpt":"N1"},
        {"kanji":"温", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"穏", "stroke":16, "grade":7, "jlpt":"N2"},
        {"kanji":"下", "stroke":3, "grade":1, "jlpt":"N5"},
        {"kanji":"化", "stroke":4, "grade":3, "jlpt":"N3"},
        {"kanji":"火", "stroke":4, "grade":1, "jlpt":"N5"},
        {"kanji":"加", "stroke":5, "grade":4, "jlpt":"N3"},
        {"kanji":"可", "stroke":5, "grade":5, "jlpt":"N3"},
        {"kanji":"仮", "stroke":6, "grade":5, "jlpt":"N2"},
        {"kanji":"何", "stroke":7, "grade":2, "jlpt":"N5"},
        {"kanji":"花", "stroke":7, "grade":1, "jlpt":"N5"},
        {"kanji":"佳", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"価", "stroke":8, "grade":5, "jlpt":"N3"},
        {"kanji":"果", "stroke":8, "grade":4, "jlpt":"N3"},
        {"kanji":"河", "stroke":8, "grade":5, "jlpt":"N2"},
        {"kanji":"苛", "stroke":8, "grade":7, "jlpt":"-"},
        {"kanji":"科", "stroke":9, "grade":2, "jlpt":"N4"},
        {"kanji":"架", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"夏", "stroke":10, "grade":2, "jlpt":"N4"},
        {"kanji":"家", "stroke":10, "grade":2, "jlpt":"N4"},
        {"kanji":"荷", "stroke":10, "grade":3, "jlpt":"N3"},
        {"kanji":"華", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"菓", "stroke":11, "grade":7, "jlpt":"N3"},
        {"kanji":"貨", "stroke":11, "grade":4, "jlpt":"N2"},
        {"kanji":"渦", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"過", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"嫁", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"暇", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"禍", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"靴", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"寡", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"歌", "stroke":14, "grade":2, "jlpt":"N4"},
        {"kanji":"箇", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"稼", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"課", "stroke":15, "grade":4, "jlpt":"N3"},
        {"kanji":"蚊", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"牙", "stroke":4, "grade":7, "jlpt":"-"},
        {"kanji":"瓦", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"我", "stroke":7, "grade":6, "jlpt":"N2"},
        {"kanji":"画", "stroke":8, "grade":2, "jlpt":"N4"},
        {"kanji":"芽", "stroke":8, "grade":4, "jlpt":"N1"},
        {"kanji":"賀", "stroke":12, "grade":4, "jlpt":"N1"},
        {"kanji":"雅", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"餓", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"介", "stroke":4, "grade":7, "jlpt":"N3"},
        {"kanji":"回", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"灰", "stroke":6, "grade":6, "jlpt":"N2"},
        {"kanji":"会", "stroke":6, "grade":2, "jlpt":"N5"},
        {"kanji":"快", "stroke":7, "grade":5, "jlpt":"N3"},
        {"kanji":"戒", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"改", "stroke":7, "grade":4, "jlpt":"N3"},
        {"kanji":"怪", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"拐", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"悔", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"海", "stroke":9, "grade":2, "jlpt":"N4"},
        {"kanji":"界", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"皆", "stroke":9, "grade":7, "jlpt":"N3"},
        {"kanji":"械", "stroke":11, "grade":4, "jlpt":"N3"},
        {"kanji":"絵", "stroke":12, "grade":2, "jlpt":"N3"},
        {"kanji":"開", "stroke":12, "grade":3, "jlpt":"N4"},
        {"kanji":"階", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"塊", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"楷", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"解", "stroke":13, "grade":5, "jlpt":"N3"},
        {"kanji":"潰", "stroke":15, "grade":7, "jlpt":"-"},
        {"kanji":"壊", "stroke":16, "grade":7, "jlpt":"N2"},
        {"kanji":"懐", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"諧", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"貝", "stroke":7, "grade":1, "jlpt":"N3"},
        {"kanji":"外", "stroke":5, "grade":2, "jlpt":"N5"},
        {"kanji":"劾", "stroke":8, "grade":7, "jlpt":"-"},
        {"kanji":"害", "stroke":10, "grade":4, "jlpt":"N2"},
        {"kanji":"崖", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"涯", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"街", "stroke":12, "grade":4, "jlpt":"N2"},
        {"kanji":"慨", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"蓋", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"該", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"概", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"骸", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"垣", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"柿", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"各", "stroke":6, "grade":4, "jlpt":"N3"},
        {"kanji":"角", "stroke":7, "grade":2, "jlpt":"N3"},
        {"kanji":"拡", "stroke":8, "grade":6, "jlpt":"N2"},
        {"kanji":"革", "stroke":9, "grade":6, "jlpt":"N2"},
        {"kanji":"格", "stroke":10, "grade":5, "jlpt":"N3"},
        {"kanji":"核", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"殻", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"郭", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"覚", "stroke":12, "grade":4, "jlpt":"N3"},
        {"kanji":"較", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"隔", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"閣", "stroke":14, "grade":6, "jlpt":"N1"},
        {"kanji":"確", "stroke":15, "grade":5, "jlpt":"N3"},
        {"kanji":"獲", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"嚇", "stroke":17, "grade":7, "jlpt":"-"},
        {"kanji":"穫", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"学", "stroke":8, "grade":1, "jlpt":"N5"},
        {"kanji":"岳", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"楽", "stroke":13, "grade":2, "jlpt":"N4"},
        {"kanji":"額", "stroke":18, "grade":5, "jlpt":"N3"},
        {"kanji":"顎", "stroke":18, "grade":7, "jlpt":"-"},
        {"kanji":"掛", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"潟", "stroke":15, "grade":4, "jlpt":"N1"},
        {"kanji":"括", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"活", "stroke":9, "grade":2, "jlpt":"N3"},
        {"kanji":"喝", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"渇", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"割", "stroke":12, "grade":6, "jlpt":"N3"},
        {"kanji":"葛", "stroke":12, "grade":7, "jlpt":"-"},
        {"kanji":"滑", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"褐", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"轄", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"且", "stroke":5, "grade":7, "jlpt":"-"},
        {"kanji":"株", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"釜", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"鎌", "stroke":18, "grade":7, "jlpt":"-"},
        {"kanji":"刈", "stroke":4, "grade":7, "jlpt":"N1"},
        {"kanji":"干", "stroke":3, "grade":6, "jlpt":"N2"},
        {"kanji":"刊", "stroke":5, "grade":5, "jlpt":"N2"},
        {"kanji":"甘", "stroke":5, "grade":7, "jlpt":"N2"},
        {"kanji":"汗", "stroke":6, "grade":7, "jlpt":"N3"},
        {"kanji":"缶", "stroke":6, "grade":7, "jlpt":"N2"},
        {"kanji":"完", "stroke":7, "grade":4, "jlpt":"N3"},
        {"kanji":"肝", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"官", "stroke":8, "grade":4, "jlpt":"N2"},
        {"kanji":"冠", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"巻", "stroke":9, "grade":6, "jlpt":"N2"},
        {"kanji":"看", "stroke":9, "grade":6, "jlpt":"N2"},
        {"kanji":"陥", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"乾", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"勘", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"患", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"貫", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"寒", "stroke":12, "grade":3, "jlpt":"N4"},
        {"kanji":"喚", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"堪", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"換", "stroke":12, "grade":7, "jlpt":"N3"},
        {"kanji":"敢", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"棺", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"款", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"間", "stroke":12, "grade":2, "jlpt":"N5"},
        {"kanji":"閑", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"勧", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"寛", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"幹", "stroke":13, "grade":5, "jlpt":"N1"},
        {"kanji":"感", "stroke":13, "grade":3, "jlpt":"N3"},
        {"kanji":"漢", "stroke":13, "grade":3, "jlpt":"N4"},
        {"kanji":"慣", "stroke":14, "grade":5, "jlpt":"N3"},
        {"kanji":"管", "stroke":14, "grade":4, "jlpt":"N3"},
        {"kanji":"関", "stroke":14, "grade":4, "jlpt":"N3"},
        {"kanji":"歓", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"監", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"緩", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"憾", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"還", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"館", "stroke":16, "grade":3, "jlpt":"N4"},
        {"kanji":"環", "stroke":17, "grade":7, "jlpt":"N2"},
        {"kanji":"簡", "stroke":18, "grade":6, "jlpt":"N3"},
        {"kanji":"観", "stroke":18, "grade":4, "jlpt":"N3"},
        {"kanji":"韓", "stroke":18, "grade":7, "jlpt":"-"},
        {"kanji":"艦", "stroke":21, "grade":7, "jlpt":"N1"},
        {"kanji":"鑑", "stroke":23, "grade":7, "jlpt":"N1"},
        {"kanji":"丸", "stroke":3, "grade":2, "jlpt":"N3"},
        {"kanji":"含", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"岸", "stroke":8, "grade":3, "jlpt":"N2"},
        {"kanji":"岩", "stroke":8, "grade":2, "jlpt":"N4"},
        {"kanji":"玩", "stroke":8, "grade":7, "jlpt":"-"},
        {"kanji":"眼", "stroke":11, "grade":5, "jlpt":"N1"},
        {"kanji":"頑", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"顔", "stroke":18, "grade":2, "jlpt":"N4"},
        {"kanji":"願", "stroke":19, "grade":4, "jlpt":"N3"},
        {"kanji":"企", "stroke":6, "grade":7, "jlpt":"N2"},
        {"kanji":"伎", "stroke":6, "grade":7, "jlpt":"-"},
        {"kanji":"危", "stroke":6, "grade":6, "jlpt":"N4"},
        {"kanji":"机", "stroke":6, "grade":6, "jlpt":"N2"},
        {"kanji":"気", "stroke":6, "grade":1, "jlpt":"N5"},
        {"kanji":"岐", "stroke":7, "grade":4, "jlpt":"N1"},
        {"kanji":"希", "stroke":7, "grade":4, "jlpt":"N3"},
        {"kanji":"忌", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"汽", "stroke":7, "grade":2, "jlpt":"N1"},
        {"kanji":"奇", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"祈", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"季", "stroke":8, "grade":4, "jlpt":"N2"},
        {"kanji":"紀", "stroke":9, "grade":5, "jlpt":"N1"},
        {"kanji":"軌", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"既", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"記", "stroke":10, "grade":2, "jlpt":"N3"},
        {"kanji":"起", "stroke":10, "grade":3, "jlpt":"N4"},
        {"kanji":"飢", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"鬼", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"帰", "stroke":10, "grade":2, "jlpt":"N4"},
        {"kanji":"基", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"寄", "stroke":11, "grade":5, "jlpt":"N2"},
        {"kanji":"規", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"亀", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"喜", "stroke":12, "grade":5, "jlpt":"N2"},
        {"kanji":"幾", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"揮", "stroke":12, "grade":6, "jlpt":"N1"},
        {"kanji":"期", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"棋", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"貴", "stroke":12, "grade":6, "jlpt":"N2"},
        {"kanji":"棄", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"毀", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"旗", "stroke":14, "grade":4, "jlpt":"N1"},
        {"kanji":"器", "stroke":15, "grade":4, "jlpt":"N3"},
        {"kanji":"畿", "stroke":15, "grade":7, "jlpt":"-"},
        {"kanji":"輝", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"機", "stroke":16, "grade":4, "jlpt":"N3"},
        {"kanji":"騎", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"技", "stroke":7, "grade":5, "jlpt":"N3"},
        {"kanji":"宜", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"偽", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"欺", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"義", "stroke":13, "grade":5, "jlpt":"N2"},
        {"kanji":"疑", "stroke":14, "grade":6, "jlpt":"N3"},
        {"kanji":"儀", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"戯", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"擬", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"犠", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"議", "stroke":20, "grade":4, "jlpt":"N3"},
        {"kanji":"菊", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"吉", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"喫", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"詰", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"却", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"客", "stroke":9, "grade":3, "jlpt":"N3"},
        {"kanji":"脚", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"逆", "stroke":9, "grade":5, "jlpt":"N2"},
        {"kanji":"虐", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"九", "stroke":2, "grade":1, "jlpt":"N5"},
        {"kanji":"久", "stroke":3, "grade":5, "jlpt":"N3"},
        {"kanji":"及", "stroke":3, "grade":7, "jlpt":"N2"},
        {"kanji":"弓", "stroke":3, "grade":2, "jlpt":"N1"},
        {"kanji":"丘", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"旧", "stroke":5, "grade":5, "jlpt":"N2"},
        {"kanji":"休", "stroke":6, "grade":1, "jlpt":"N5"},
        {"kanji":"吸", "stroke":6, "grade":6, "jlpt":"N3"},
        {"kanji":"朽", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"臼", "stroke":6, "grade":7, "jlpt":"-"},
        {"kanji":"求", "stroke":7, "grade":4, "jlpt":"N3"},
        {"kanji":"究", "stroke":7, "grade":3, "jlpt":"N4"},
        {"kanji":"泣", "stroke":8, "grade":4, "jlpt":"N3"},
        {"kanji":"急", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"級", "stroke":9, "grade":3, "jlpt":"N3"},
        {"kanji":"糾", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"宮", "stroke":10, "grade":3, "jlpt":"N1"},
        {"kanji":"救", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"球", "stroke":11, "grade":3, "jlpt":"N3"},
        {"kanji":"給", "stroke":12, "grade":4, "jlpt":"N3"},
        {"kanji":"嗅", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"窮", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"牛", "stroke":4, "grade":2, "jlpt":"N5"},
        {"kanji":"去", "stroke":5, "grade":3, "jlpt":"N5"},
        {"kanji":"巨", "stroke":5, "grade":7, "jlpt":"N2"},
        {"kanji":"居", "stroke":8, "grade":5, "jlpt":"N2"},
        {"kanji":"拒", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"拠", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"挙", "stroke":10, "grade":4, "jlpt":"N2"},
        {"kanji":"虚", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"許", "stroke":11, "grade":5, "jlpt":"N2"},
        {"kanji":"距", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"魚", "stroke":11, "grade":2, "jlpt":"N5"},
        {"kanji":"御", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"漁", "stroke":14, "grade":4, "jlpt":"N2"},
        {"kanji":"凶", "stroke":4, "grade":7, "jlpt":"N1"},
        {"kanji":"共", "stroke":6, "grade":4, "jlpt":"N3"},
        {"kanji":"叫", "stroke":6, "grade":7, "jlpt":"N2"},
        {"kanji":"狂", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"京", "stroke":8, "grade":2, "jlpt":"N4"},
        {"kanji":"享", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"供", "stroke":8, "grade":6, "jlpt":"N3"},
        {"kanji":"協", "stroke":8, "grade":4, "jlpt":"N3"},
        {"kanji":"況", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"峡", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"挟", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"狭", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"恐", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"恭", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"胸", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"脅", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"強", "stroke":11, "grade":2, "jlpt":"N4"},
        {"kanji":"教", "stroke":11, "grade":2, "jlpt":"N4"},
        {"kanji":"郷", "stroke":11, "grade":6, "jlpt":"N1"},
        {"kanji":"境", "stroke":14, "grade":5, "jlpt":"N2"},
        {"kanji":"橋", "stroke":16, "grade":3, "jlpt":"N3"},
        {"kanji":"矯", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"鏡", "stroke":19, "grade":4, "jlpt":"N1"},
        {"kanji":"競", "stroke":20, "grade":4, "jlpt":"N2"},
        {"kanji":"響", "stroke":20, "grade":7, "jlpt":"N2"},
        {"kanji":"驚", "stroke":22, "grade":7, "jlpt":"N2"},
        {"kanji":"仰", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"暁", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"業", "stroke":13, "grade":3, "jlpt":"N4"},
        {"kanji":"凝", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"曲", "stroke":6, "grade":3, "jlpt":"N3"},
        {"kanji":"局", "stroke":7, "grade":3, "jlpt":"N3"},
        {"kanji":"極", "stroke":12, "grade":4, "jlpt":"N2"},
        {"kanji":"玉", "stroke":5, "grade":1, "jlpt":"N3"},
        {"kanji":"巾", "stroke":3, "grade":7, "jlpt":"-"},
        {"kanji":"斤", "stroke":4, "grade":7, "jlpt":"N1"},
        {"kanji":"均", "stroke":7, "grade":5, "jlpt":"N2"},
        {"kanji":"近", "stroke":7, "grade":2, "jlpt":"N4"},
        {"kanji":"金", "stroke":8, "grade":1, "jlpt":"N5"},
        {"kanji":"菌", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"勤", "stroke":12, "grade":6, "jlpt":"N3"},
        {"kanji":"琴", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"筋", "stroke":12, "grade":6, "jlpt":"N2"},
        {"kanji":"僅", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"禁", "stroke":13, "grade":5, "jlpt":"N3"},
        {"kanji":"緊", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"錦", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"謹", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"襟", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"吟", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"銀", "stroke":14, "grade":3, "jlpt":"N4"},
        {"kanji":"区", "stroke":4, "grade":3, "jlpt":"N4"},
        {"kanji":"句", "stroke":5, "grade":5, "jlpt":"N2"},
        {"kanji":"苦", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"駆", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"具", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"惧", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"愚", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"空", "stroke":8, "grade":1, "jlpt":"N5"},
        {"kanji":"偶", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"遇", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"隅", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"串", "stroke":7, "grade":7, "jlpt":"-"},
        {"kanji":"屈", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"掘", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"窟", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"熊", "stroke":14, "grade":4, "jlpt":"-"},
        {"kanji":"繰", "stroke":19, "grade":7, "jlpt":"N2"},
        {"kanji":"君", "stroke":7, "grade":3, "jlpt":"N3"},
        {"kanji":"訓", "stroke":10, "grade":4, "jlpt":"N2"},
        {"kanji":"勲", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"薫", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"軍", "stroke":9, "grade":4, "jlpt":"N2"},
        {"kanji":"郡", "stroke":10, "grade":4, "jlpt":"N1"},
        {"kanji":"群", "stroke":13, "grade":4, "jlpt":"N2"},
        {"kanji":"兄", "stroke":5, "grade":2, "jlpt":"N4"},
        {"kanji":"刑", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"形", "stroke":7, "grade":2, "jlpt":"N3"},
        {"kanji":"系", "stroke":7, "grade":6, "jlpt":"N2"},
        {"kanji":"径", "stroke":8, "grade":4, "jlpt":"N1"},
        {"kanji":"茎", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"係", "stroke":9, "grade":3, "jlpt":"N3"},
        {"kanji":"型", "stroke":9, "grade":5, "jlpt":"N3"},
        {"kanji":"契", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"計", "stroke":9, "grade":2, "jlpt":"N4"},
        {"kanji":"恵", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"啓", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"掲", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"渓", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"経", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"蛍", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"敬", "stroke":12, "grade":6, "jlpt":"N2"},
        {"kanji":"景", "stroke":12, "grade":4, "jlpt":"N2"},
        {"kanji":"軽", "stroke":12, "grade":3, "jlpt":"N4"},
        {"kanji":"傾", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"携", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"継", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"詣", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"慶", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"憬", "stroke":15, "grade":7, "jlpt":"-"},
        {"kanji":"稽", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"憩", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"警", "stroke":19, "grade":6, "jlpt":"N3"},
        {"kanji":"鶏", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"芸", "stroke":7, "grade":4, "jlpt":"N3"},
        {"kanji":"迎", "stroke":7, "grade":7, "jlpt":"N3"},
        {"kanji":"鯨", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"隙", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"劇", "stroke":15, "grade":6, "jlpt":"N2"},
        {"kanji":"撃", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"激", "stroke":16, "grade":6, "jlpt":"N2"},
        {"kanji":"桁", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"欠", "stroke":4, "grade":4, "jlpt":"N3"},
        {"kanji":"穴", "stroke":5, "grade":6, "jlpt":"N2"},
        {"kanji":"血", "stroke":6, "grade":3, "jlpt":"N3"},
        {"kanji":"決", "stroke":7, "grade":3, "jlpt":"N3"},
        {"kanji":"結", "stroke":12, "grade":4, "jlpt":"N3"},
        {"kanji":"傑", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"潔", "stroke":15, "grade":5, "jlpt":"N2"},
        {"kanji":"月", "stroke":4, "grade":1, "jlpt":"N5"},
        {"kanji":"犬", "stroke":4, "grade":1, "jlpt":"N5"},
        {"kanji":"件", "stroke":6, "grade":5, "jlpt":"N3"},
        {"kanji":"見", "stroke":7, "grade":1, "jlpt":"N5"},
        {"kanji":"券", "stroke":8, "grade":6, "jlpt":"N3"},
        {"kanji":"肩", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"建", "stroke":9, "grade":4, "jlpt":"N4"},
        {"kanji":"研", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"県", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"倹", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"兼", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"剣", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"拳", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"軒", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"健", "stroke":11, "grade":4, "jlpt":"N3"},
        {"kanji":"険", "stroke":11, "grade":5, "jlpt":"N4"},
        {"kanji":"圏", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"堅", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"検", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"嫌", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"献", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"絹", "stroke":13, "grade":6, "jlpt":"N1"},
        {"kanji":"遣", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"権", "stroke":15, "grade":6, "jlpt":"N2"},
        {"kanji":"憲", "stroke":16, "grade":6, "jlpt":"N1"},
        {"kanji":"賢", "stroke":16, "grade":7, "jlpt":"N2"},
        {"kanji":"謙", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"鍵", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"繭", "stroke":18, "grade":7, "jlpt":"-"},
        {"kanji":"顕", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"験", "stroke":18, "grade":4, "jlpt":"N4"},
        {"kanji":"懸", "stroke":20, "grade":7, "jlpt":"N1"},
        {"kanji":"元", "stroke":4, "grade":2, "jlpt":"N4"},
        {"kanji":"幻", "stroke":4, "grade":7, "jlpt":"N1"},
        {"kanji":"玄", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"言", "stroke":7, "grade":2, "jlpt":"N5"},
        {"kanji":"弦", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"限", "stroke":9, "grade":5, "jlpt":"N3"},
        {"kanji":"原", "stroke":10, "grade":2, "jlpt":"N3"},
        {"kanji":"現", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"舷", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"減", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"源", "stroke":13, "grade":6, "jlpt":"N2"},
        {"kanji":"厳", "stroke":17, "grade":6, "jlpt":"N2"},
        {"kanji":"己", "stroke":3, "grade":6, "jlpt":"N1"},
        {"kanji":"戸", "stroke":4, "grade":2, "jlpt":"N3"},
        {"kanji":"古", "stroke":5, "grade":2, "jlpt":"N5"},
        {"kanji":"呼", "stroke":8, "grade":6, "jlpt":"N3"},
        {"kanji":"固", "stroke":8, "grade":4, "jlpt":"N3"},
        {"kanji":"股", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"虎", "stroke":8, "grade":7, "jlpt":"-"},
        {"kanji":"孤", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"弧", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"故", "stroke":9, "grade":5, "jlpt":"N3"},
        {"kanji":"枯", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"個", "stroke":10, "grade":5, "jlpt":"N3"},
        {"kanji":"庫", "stroke":10, "grade":3, "jlpt":"N3"},
        {"kanji":"湖", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"雇", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"誇", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"鼓", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"錮", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"顧", "stroke":21, "grade":7, "jlpt":"N1"},
        {"kanji":"五", "stroke":4, "grade":1, "jlpt":"N5"},
        {"kanji":"互", "stroke":4, "grade":7, "jlpt":"N2"},
        {"kanji":"午", "stroke":4, "grade":2, "jlpt":"N5"},
        {"kanji":"呉", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"後", "stroke":9, "grade":2, "jlpt":"N5"},
        {"kanji":"娯", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"悟", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"碁", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"語", "stroke":14, "grade":2, "jlpt":"N5"},
        {"kanji":"誤", "stroke":14, "grade":6, "jlpt":"N2"},
        {"kanji":"護", "stroke":20, "grade":5, "jlpt":"N2"},
        {"kanji":"口", "stroke":3, "grade":1, "jlpt":"N5"},
        {"kanji":"工", "stroke":3, "grade":2, "jlpt":"N4"},
        {"kanji":"公", "stroke":4, "grade":2, "jlpt":"N4"},
        {"kanji":"勾", "stroke":4, "grade":7, "jlpt":"-"},
        {"kanji":"孔", "stroke":4, "grade":7, "jlpt":"N1"},
        {"kanji":"功", "stroke":5, "grade":4, "jlpt":"N2"},
        {"kanji":"巧", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"広", "stroke":5, "grade":2, "jlpt":"N4"},
        {"kanji":"甲", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"交", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"光", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"向", "stroke":6, "grade":3, "jlpt":"N3"},
        {"kanji":"后", "stroke":6, "grade":6, "jlpt":"N1"},
        {"kanji":"好", "stroke":6, "grade":4, "jlpt":"N4"},
        {"kanji":"江", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"考", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"行", "stroke":6, "grade":2, "jlpt":"N5"},
        {"kanji":"坑", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"孝", "stroke":7, "grade":6, "jlpt":"N1"},
        {"kanji":"抗", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"攻", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"更", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"効", "stroke":8, "grade":5, "jlpt":"N3"},
        {"kanji":"幸", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"拘", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"肯", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"侯", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"厚", "stroke":9, "grade":5, "jlpt":"N3"},
        {"kanji":"恒", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"洪", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"皇", "stroke":9, "grade":6, "jlpt":"N1"},
        {"kanji":"紅", "stroke":9, "grade":6, "jlpt":"N3"},
        {"kanji":"荒", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"郊", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"香", "stroke":9, "grade":4, "jlpt":"N3"},
        {"kanji":"候", "stroke":10, "grade":4, "jlpt":"N2"},
        {"kanji":"校", "stroke":10, "grade":1, "jlpt":"N5"},
        {"kanji":"耕", "stroke":10, "grade":5, "jlpt":"N2"},
        {"kanji":"航", "stroke":10, "grade":5, "jlpt":"N2"},
        {"kanji":"貢", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"降", "stroke":10, "grade":6, "jlpt":"N4"},
        {"kanji":"高", "stroke":10, "grade":2, "jlpt":"N5"},
        {"kanji":"康", "stroke":11, "grade":4, "jlpt":"N3"},
        {"kanji":"控", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"梗", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"黄", "stroke":11, "grade":2, "jlpt":"N4"},
        {"kanji":"喉", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"慌", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"港", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"硬", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"絞", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"項", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"溝", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"鉱", "stroke":13, "grade":5, "jlpt":"N2"},
        {"kanji":"構", "stroke":14, "grade":5, "jlpt":"N2"},
        {"kanji":"綱", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"酵", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"稿", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"興", "stroke":16, "grade":5, "jlpt":"N2"},
        {"kanji":"衡", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"鋼", "stroke":16, "grade":6, "jlpt":"N1"},
        {"kanji":"講", "stroke":17, "grade":5, "jlpt":"N2"},
        {"kanji":"購", "stroke":17, "grade":7, "jlpt":"N2"},
        {"kanji":"乞", "stroke":3, "grade":7, "jlpt":"-"},
        {"kanji":"号", "stroke":5, "grade":3, "jlpt":"N4"},
        {"kanji":"合", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"拷", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"剛", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"傲", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"豪", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"克", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"告", "stroke":7, "grade":5, "jlpt":"N3"},
        {"kanji":"谷", "stroke":7, "grade":2, "jlpt":"N2"},
        {"kanji":"刻", "stroke":8, "grade":6, "jlpt":"N2"},
        {"kanji":"国", "stroke":8, "grade":2, "jlpt":"N5"},
        {"kanji":"黒", "stroke":11, "grade":2, "jlpt":"N4"},
        {"kanji":"穀", "stroke":14, "grade":6, "jlpt":"N1"},
        {"kanji":"酷", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"獄", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"骨", "stroke":10, "grade":6, "jlpt":"N3"},
        {"kanji":"駒", "stroke":15, "grade":7, "jlpt":"-"},
        {"kanji":"込", "stroke":5, "grade":7, "jlpt":"N3"},
        {"kanji":"頃", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"今", "stroke":4, "grade":2, "jlpt":"N5"},
        {"kanji":"困", "stroke":7, "grade":6, "jlpt":"N3"},
        {"kanji":"昆", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"恨", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"根", "stroke":10, "grade":3, "jlpt":"N3"},
        {"kanji":"婚", "stroke":11, "grade":7, "jlpt":"N3"},
        {"kanji":"混", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"痕", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"紺", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"魂", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"墾", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"懇", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"左", "stroke":5, "grade":1, "jlpt":"N5"},
        {"kanji":"佐", "stroke":7, "grade":4, "jlpt":"N1"},
        {"kanji":"沙", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"査", "stroke":9, "grade":5, "jlpt":"N3"},
        {"kanji":"砂", "stroke":9, "grade":6, "jlpt":"N3"},
        {"kanji":"唆", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"差", "stroke":10, "grade":4, "jlpt":"N3"},
        {"kanji":"詐", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"鎖", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"座", "stroke":10, "grade":6, "jlpt":"N4"},
        {"kanji":"挫", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"才", "stroke":3, "grade":2, "jlpt":"N3"},
        {"kanji":"再", "stroke":6, "grade":5, "jlpt":"N3"},
        {"kanji":"災", "stroke":7, "grade":5, "jlpt":"N2"},
        {"kanji":"妻", "stroke":8, "grade":5, "jlpt":"N3"},
        {"kanji":"采", "stroke":8, "grade":7, "jlpt":"-"},
        {"kanji":"砕", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"宰", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"栽", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"彩", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"採", "stroke":11, "grade":5, "jlpt":"N2"},
        {"kanji":"済", "stroke":11, "grade":6, "jlpt":"N3"},
        {"kanji":"祭", "stroke":11, "grade":3, "jlpt":"N3"},
        {"kanji":"斎", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"細", "stroke":11, "grade":2, "jlpt":"N3"},
        {"kanji":"菜", "stroke":11, "grade":4, "jlpt":"N4"},
        {"kanji":"最", "stroke":12, "grade":4, "jlpt":"N3"},
        {"kanji":"裁", "stroke":12, "grade":6, "jlpt":"N2"},
        {"kanji":"債", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"催", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"塞", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"歳", "stroke":13, "grade":7, "jlpt":"N3"},
        {"kanji":"載", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"際", "stroke":14, "grade":5, "jlpt":"N3"},
        {"kanji":"埼", "stroke":11, "grade":4, "jlpt":"-"},
        {"kanji":"在", "stroke":6, "grade":5, "jlpt":"N3"},
        {"kanji":"材", "stroke":7, "grade":4, "jlpt":"N3"},
        {"kanji":"剤", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"財", "stroke":10, "grade":5, "jlpt":"N3"},
        {"kanji":"罪", "stroke":13, "grade":5, "jlpt":"N2"},
        {"kanji":"崎", "stroke":11, "grade":4, "jlpt":"-"},
        {"kanji":"作", "stroke":7, "grade":2, "jlpt":"N4"},
        {"kanji":"削", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"昨", "stroke":9, "grade":4, "jlpt":"N3"},
        {"kanji":"柵", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"索", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"策", "stroke":12, "grade":6, "jlpt":"N2"},
        {"kanji":"酢", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"搾", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"錯", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"咲", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"冊", "stroke":5, "grade":6, "jlpt":"N3"},
        {"kanji":"札", "stroke":5, "grade":4, "jlpt":"N3"},
        {"kanji":"刷", "stroke":8, "grade":4, "jlpt":"N2"},
        {"kanji":"刹", "stroke":8, "grade":7, "jlpt":"-"},
        {"kanji":"拶", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"殺", "stroke":10, "grade":5, "jlpt":"N3"},
        {"kanji":"察", "stroke":14, "grade":4, "jlpt":"N3"},
        {"kanji":"撮", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"擦", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"雑", "stroke":14, "grade":5, "jlpt":"N3"},
        {"kanji":"皿", "stroke":5, "grade":3, "jlpt":"N3"},
        {"kanji":"三", "stroke":3, "grade":1, "jlpt":"N5"},
        {"kanji":"山", "stroke":3, "grade":1, "jlpt":"N5"},
        {"kanji":"参", "stroke":8, "grade":4, "jlpt":"N3"},
        {"kanji":"桟", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"蚕", "stroke":10, "grade":6, "jlpt":"N1"},
        {"kanji":"惨", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"産", "stroke":11, "grade":4, "jlpt":"N4"},
        {"kanji":"傘", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"散", "stroke":12, "grade":4, "jlpt":"N2"},
        {"kanji":"算", "stroke":14, "grade":2, "jlpt":"N3"},
        {"kanji":"酸", "stroke":14, "grade":5, "jlpt":"N1"},
        {"kanji":"賛", "stroke":15, "grade":5, "jlpt":"N2"},
        {"kanji":"残", "stroke":10, "grade":4, "jlpt":"N3"},
        {"kanji":"斬", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"暫", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"士", "stroke":3, "grade":5, "jlpt":"N2"},
        {"kanji":"子", "stroke":3, "grade":1, "jlpt":"N5"},
        {"kanji":"支", "stroke":4, "grade":5, "jlpt":"N3"},
        {"kanji":"止", "stroke":4, "grade":2, "jlpt":"N4"},
        {"kanji":"氏", "stroke":4, "grade":4, "jlpt":"N2"},
        {"kanji":"仕", "stroke":5, "grade":3, "jlpt":"N4"},
        {"kanji":"史", "stroke":5, "grade":5, "jlpt":"N3"},
        {"kanji":"司", "stroke":5, "grade":4, "jlpt":"N3"},
        {"kanji":"四", "stroke":5, "grade":1, "jlpt":"N5"},
        {"kanji":"市", "stroke":5, "grade":2, "jlpt":"N4"},
        {"kanji":"矢", "stroke":5, "grade":2, "jlpt":"N1"},
        {"kanji":"旨", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"死", "stroke":6, "grade":3, "jlpt":"N4"},
        {"kanji":"糸", "stroke":6, "grade":1, "jlpt":"N3"},
        {"kanji":"至", "stroke":6, "grade":6, "jlpt":"N2"},
        {"kanji":"伺", "stroke":7, "grade":7, "jlpt":"N3"},
        {"kanji":"志", "stroke":7, "grade":5, "jlpt":"N2"},
        {"kanji":"私", "stroke":7, "grade":6, "jlpt":"N4"},
        {"kanji":"使", "stroke":8, "grade":3, "jlpt":"N4"},
        {"kanji":"刺", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"始", "stroke":8, "grade":3, "jlpt":"N4"},
        {"kanji":"姉", "stroke":8, "grade":2, "jlpt":"N4"},
        {"kanji":"枝", "stroke":8, "grade":5, "jlpt":"N2"},
        {"kanji":"祉", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"肢", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"姿", "stroke":9, "grade":6, "jlpt":"N2"},
        {"kanji":"思", "stroke":9, "grade":2, "jlpt":"N4"},
        {"kanji":"指", "stroke":9, "grade":3, "jlpt":"N3"},
        {"kanji":"施", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"師", "stroke":10, "grade":5, "jlpt":"N3"},
        {"kanji":"恣", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"紙", "stroke":10, "grade":2, "jlpt":"N4"},
        {"kanji":"脂", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"視", "stroke":11, "grade":6, "jlpt":"N2"},
        {"kanji":"紫", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"詞", "stroke":12, "grade":6, "jlpt":"N2"},
        {"kanji":"歯", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"嗣", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"試", "stroke":13, "grade":4, "jlpt":"N4"},
        {"kanji":"詩", "stroke":13, "grade":3, "jlpt":"N2"},
        {"kanji":"資", "stroke":13, "grade":5, "jlpt":"N3"},
        {"kanji":"飼", "stroke":13, "grade":5, "jlpt":"N1"},
        {"kanji":"誌", "stroke":14, "grade":6, "jlpt":"N3"},
        {"kanji":"雌", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"摯", "stroke":15, "grade":7, "jlpt":"-"},
        {"kanji":"賜", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"諮", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"示", "stroke":5, "grade":5, "jlpt":"N3"},
        {"kanji":"字", "stroke":6, "grade":1, "jlpt":"N4"},
        {"kanji":"寺", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"次", "stroke":6, "grade":3, "jlpt":"N4"},
        {"kanji":"耳", "stroke":6, "grade":1, "jlpt":"N5"},
        {"kanji":"自", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"似", "stroke":7, "grade":5, "jlpt":"N2"},
        {"kanji":"児", "stroke":7, "grade":4, "jlpt":"N2"},
        {"kanji":"事", "stroke":8, "grade":3, "jlpt":"N4"},
        {"kanji":"侍", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"治", "stroke":8, "grade":4, "jlpt":"N3"},
        {"kanji":"持", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"時", "stroke":10, "grade":2, "jlpt":"N5"},
        {"kanji":"滋", "stroke":12, "grade":4, "jlpt":"N1"},
        {"kanji":"慈", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"辞", "stroke":13, "grade":4, "jlpt":"N3"},
        {"kanji":"磁", "stroke":14, "grade":6, "jlpt":"N1"},
        {"kanji":"餌", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"璽", "stroke":19, "grade":7, "jlpt":"-"},
        {"kanji":"鹿", "stroke":11, "grade":4, "jlpt":"-"},
        {"kanji":"式", "stroke":6, "grade":3, "jlpt":"N3"},
        {"kanji":"識", "stroke":19, "grade":5, "jlpt":"N2"},
        {"kanji":"軸", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"七", "stroke":2, "grade":1, "jlpt":"N5"},
        {"kanji":"叱", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"失", "stroke":5, "grade":4, "jlpt":"N3"},
        {"kanji":"室", "stroke":9, "grade":2, "jlpt":"N4"},
        {"kanji":"疾", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"執", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"湿", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"嫉", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"漆", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"質", "stroke":15, "grade":5, "jlpt":"N4"},
        {"kanji":"実", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"芝", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"写", "stroke":5, "grade":3, "jlpt":"N4"},
        {"kanji":"社", "stroke":7, "grade":2, "jlpt":"N5"},
        {"kanji":"車", "stroke":7, "grade":1, "jlpt":"N5"},
        {"kanji":"舎", "stroke":8, "grade":5, "jlpt":"N1"},
        {"kanji":"者", "stroke":8, "grade":3, "jlpt":"N4"},
        {"kanji":"射", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"捨", "stroke":11, "grade":6, "jlpt":"N3"},
        {"kanji":"赦", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"斜", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"煮", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"遮", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"謝", "stroke":17, "grade":5, "jlpt":"N2"},
        {"kanji":"邪", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"蛇", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"尺", "stroke":4, "grade":6, "jlpt":"N1"},
        {"kanji":"借", "stroke":10, "grade":4, "jlpt":"N4"},
        {"kanji":"酌", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"釈", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"爵", "stroke":17, "grade":7, "jlpt":"-"},
        {"kanji":"若", "stroke":8, "grade":6, "jlpt":"N3"},
        {"kanji":"弱", "stroke":10, "grade":2, "jlpt":"N4"},
        {"kanji":"寂", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"手", "stroke":4, "grade":1, "jlpt":"N5"},
        {"kanji":"主", "stroke":5, "grade":3, "jlpt":"N4"},
        {"kanji":"守", "stroke":6, "grade":3, "jlpt":"N3"},
        {"kanji":"朱", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"取", "stroke":8, "grade":3, "jlpt":"N4"},
        {"kanji":"狩", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"首", "stroke":9, "grade":2, "jlpt":"N4"},
        {"kanji":"殊", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"珠", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"酒", "stroke":10, "grade":3, "jlpt":"N4"},
        {"kanji":"腫", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"種", "stroke":14, "grade":4, "jlpt":"N3"},
        {"kanji":"趣", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"寿", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"受", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"呪", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"授", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"需", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"儒", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"樹", "stroke":16, "grade":6, "jlpt":"N1"},
        {"kanji":"収", "stroke":4, "grade":6, "jlpt":"N3"},
        {"kanji":"囚", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"州", "stroke":6, "grade":3, "jlpt":"N2"},
        {"kanji":"舟", "stroke":6, "grade":7, "jlpt":"N2"},
        {"kanji":"秀", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"周", "stroke":8, "grade":4, "jlpt":"N2"},
        {"kanji":"宗", "stroke":8, "grade":6, "jlpt":"N1"},
        {"kanji":"拾", "stroke":9, "grade":3, "jlpt":"N2"},
        {"kanji":"秋", "stroke":9, "grade":2, "jlpt":"N4"},
        {"kanji":"臭", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"修", "stroke":10, "grade":5, "jlpt":"N3"},
        {"kanji":"袖", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"終", "stroke":11, "grade":3, "jlpt":"N4"},
        {"kanji":"羞", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"習", "stroke":11, "grade":3, "jlpt":"N4"},
        {"kanji":"週", "stroke":11, "grade":2, "jlpt":"N5"},
        {"kanji":"就", "stroke":12, "grade":6, "jlpt":"N2"},
        {"kanji":"衆", "stroke":12, "grade":6, "jlpt":"N1"},
        {"kanji":"集", "stroke":12, "grade":3, "jlpt":"N4"},
        {"kanji":"愁", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"酬", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"醜", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"蹴", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"襲", "stroke":22, "grade":7, "jlpt":"N1"},
        {"kanji":"十", "stroke":2, "grade":1, "jlpt":"N5"},
        {"kanji":"汁", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"充", "stroke":6, "grade":7, "jlpt":"N2"},
        {"kanji":"住", "stroke":7, "grade":3, "jlpt":"N4"},
        {"kanji":"柔", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"重", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"従", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"渋", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"銃", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"獣", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"縦", "stroke":16, "grade":6, "jlpt":"N1"},
        {"kanji":"叔", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"祝", "stroke":9, "grade":4, "jlpt":"N3"},
        {"kanji":"宿", "stroke":11, "grade":3, "jlpt":"N3"},
        {"kanji":"淑", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"粛", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"縮", "stroke":17, "grade":6, "jlpt":"N2"},
        {"kanji":"塾", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"熟", "stroke":15, "grade":6, "jlpt":"N2"},
        {"kanji":"出", "stroke":5, "grade":1, "jlpt":"N5"},
        {"kanji":"述", "stroke":8, "grade":5, "jlpt":"N2"},
        {"kanji":"術", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"俊", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"春", "stroke":9, "grade":2, "jlpt":"N4"},
        {"kanji":"瞬", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"旬", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"巡", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"盾", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"准", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"殉", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"純", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"循", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"順", "stroke":12, "grade":4, "jlpt":"N2"},
        {"kanji":"準", "stroke":13, "grade":5, "jlpt":"N3"},
        {"kanji":"潤", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"遵", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"処", "stroke":5, "grade":6, "jlpt":"N2"},
        {"kanji":"初", "stroke":7, "grade":4, "jlpt":"N3"},
        {"kanji":"所", "stroke":8, "grade":3, "jlpt":"N4"},
        {"kanji":"書", "stroke":10, "grade":2, "jlpt":"N5"},
        {"kanji":"庶", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"暑", "stroke":12, "grade":3, "jlpt":"N4"},
        {"kanji":"署", "stroke":13, "grade":6, "jlpt":"N2"},
        {"kanji":"緒", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"諸", "stroke":15, "grade":6, "jlpt":"N2"},
        {"kanji":"女", "stroke":3, "grade":1, "jlpt":"N5"},
        {"kanji":"如", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"助", "stroke":7, "grade":3, "jlpt":"N3"},
        {"kanji":"序", "stroke":7, "grade":5, "jlpt":"N2"},
        {"kanji":"叙", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"徐", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"除", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"小", "stroke":3, "grade":1, "jlpt":"N5"},
        {"kanji":"升", "stroke":4, "grade":7, "jlpt":"-"},
        {"kanji":"少", "stroke":4, "grade":2, "jlpt":"N5"},
        {"kanji":"召", "stroke":5, "grade":7, "jlpt":"N3"},
        {"kanji":"匠", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"床", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"抄", "stroke":7, "grade":7, "jlpt":"-"},
        {"kanji":"肖", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"尚", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"招", "stroke":8, "grade":5, "jlpt":"N2"},
        {"kanji":"承", "stroke":8, "grade":6, "jlpt":"N2"},
        {"kanji":"昇", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"松", "stroke":8, "grade":4, "jlpt":"N1"},
        {"kanji":"沼", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"昭", "stroke":9, "grade":3, "jlpt":"N1"},
        {"kanji":"宵", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"将", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"消", "stroke":10, "grade":3, "jlpt":"N3"},
        {"kanji":"症", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"祥", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"称", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"笑", "stroke":10, "grade":4, "jlpt":"N3"},
        {"kanji":"唱", "stroke":11, "grade":4, "jlpt":"N1"},
        {"kanji":"商", "stroke":11, "grade":3, "jlpt":"N3"},
        {"kanji":"渉", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"章", "stroke":11, "grade":3, "jlpt":"N2"},
        {"kanji":"紹", "stroke":11, "grade":7, "jlpt":"N3"},
        {"kanji":"訟", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"勝", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"掌", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"晶", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"焼", "stroke":12, "grade":4, "jlpt":"N3"},
        {"kanji":"焦", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"硝", "stroke":12, "grade":7, "jlpt":"-"},
        {"kanji":"粧", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"詔", "stroke":12, "grade":7, "jlpt":"-"},
        {"kanji":"証", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"象", "stroke":12, "grade":5, "jlpt":"N2"},
        {"kanji":"傷", "stroke":13, "grade":6, "jlpt":"N2"},
        {"kanji":"奨", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"照", "stroke":13, "grade":4, "jlpt":"N2"},
        {"kanji":"詳", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"彰", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"障", "stroke":14, "grade":6, "jlpt":"N2"},
        {"kanji":"憧", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"衝", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"賞", "stroke":15, "grade":5, "jlpt":"N3"},
        {"kanji":"償", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"礁", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"鐘", "stroke":20, "grade":7, "jlpt":"N1"},
        {"kanji":"上", "stroke":3, "grade":1, "jlpt":"N5"},
        {"kanji":"丈", "stroke":3, "grade":7, "jlpt":"N2"},
        {"kanji":"冗", "stroke":4, "grade":7, "jlpt":"N1"},
        {"kanji":"条", "stroke":7, "grade":5, "jlpt":"N3"},
        {"kanji":"状", "stroke":7, "grade":5, "jlpt":"N2"},
        {"kanji":"乗", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"城", "stroke":9, "grade":4, "jlpt":"N2"},
        {"kanji":"浄", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"剰", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"常", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"情", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"場", "stroke":12, "grade":2, "jlpt":"N4"},
        {"kanji":"畳", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"蒸", "stroke":13, "grade":6, "jlpt":"N2"},
        {"kanji":"縄", "stroke":15, "grade":4, "jlpt":"N1"},
        {"kanji":"壌", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"嬢", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"錠", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"譲", "stroke":20, "grade":7, "jlpt":"N2"},
        {"kanji":"醸", "stroke":20, "grade":7, "jlpt":"N1"},
        {"kanji":"色", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"拭", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"食", "stroke":9, "grade":2, "jlpt":"N5"},
        {"kanji":"植", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"殖", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"飾", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"触", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"嘱", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"織", "stroke":18, "grade":5, "jlpt":"N2"},
        {"kanji":"職", "stroke":18, "grade":5, "jlpt":"N3"},
        {"kanji":"辱", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"尻", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"心", "stroke":4, "grade":2, "jlpt":"N4"},
        {"kanji":"申", "stroke":5, "grade":3, "jlpt":"N3"},
        {"kanji":"伸", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"臣", "stroke":7, "grade":4, "jlpt":"N2"},
        {"kanji":"芯", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"身", "stroke":7, "grade":3, "jlpt":"N3"},
        {"kanji":"辛", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"侵", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"信", "stroke":9, "grade":4, "jlpt":"N3"},
        {"kanji":"津", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"神", "stroke":9, "grade":3, "jlpt":"N3"},
        {"kanji":"唇", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"娠", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"振", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"浸", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"真", "stroke":10, "grade":3, "jlpt":"N4"},
        {"kanji":"針", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"深", "stroke":11, "grade":3, "jlpt":"N3"},
        {"kanji":"紳", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"進", "stroke":11, "grade":3, "jlpt":"N4"},
        {"kanji":"森", "stroke":12, "grade":1, "jlpt":"N4"},
        {"kanji":"診", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"寝", "stroke":13, "grade":7, "jlpt":"N4"},
        {"kanji":"慎", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"新", "stroke":13, "grade":2, "jlpt":"N5"},
        {"kanji":"審", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"震", "stroke":15, "grade":7, "jlpt":"N3"},
        {"kanji":"薪", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"親", "stroke":16, "grade":2, "jlpt":"N4"},
        {"kanji":"人", "stroke":2, "grade":1, "jlpt":"N5"},
        {"kanji":"刃", "stroke":3, "grade":7, "jlpt":"N1"},
        {"kanji":"仁", "stroke":4, "grade":6, "jlpt":"N1"},
        {"kanji":"尽", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"迅", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"甚", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"陣", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"尋", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"腎", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"須", "stroke":12, "grade":7, "jlpt":"-"},
        {"kanji":"図", "stroke":7, "grade":2, "jlpt":"N4"},
        {"kanji":"水", "stroke":4, "grade":1, "jlpt":"N5"},
        {"kanji":"吹", "stroke":7, "grade":7, "jlpt":"N3"},
        {"kanji":"垂", "stroke":8, "grade":6, "jlpt":"N1"},
        {"kanji":"炊", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"帥", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"粋", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"衰", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"推", "stroke":11, "grade":6, "jlpt":"N2"},
        {"kanji":"酔", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"遂", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"睡", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"穂", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"随", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"髄", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"枢", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"崇", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"数", "stroke":13, "grade":2, "jlpt":"N3"},
        {"kanji":"据", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"杉", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"裾", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"寸", "stroke":3, "grade":6, "jlpt":"N2"},
        {"kanji":"瀬", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"是", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"井", "stroke":4, "grade":4, "jlpt":"N1"},
        {"kanji":"世", "stroke":5, "grade":3, "jlpt":"N4"},
        {"kanji":"正", "stroke":5, "grade":1, "jlpt":"N4"},
        {"kanji":"生", "stroke":5, "grade":1, "jlpt":"N5"},
        {"kanji":"成", "stroke":6, "grade":4, "jlpt":"N3"},
        {"kanji":"西", "stroke":6, "grade":2, "jlpt":"N5"},
        {"kanji":"声", "stroke":7, "grade":2, "jlpt":"N4"},
        {"kanji":"制", "stroke":8, "grade":5, "jlpt":"N3"},
        {"kanji":"姓", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"征", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"性", "stroke":8, "grade":5, "jlpt":"N3"},
        {"kanji":"青", "stroke":8, "grade":1, "jlpt":"N4"},
        {"kanji":"斉", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"政", "stroke":9, "grade":5, "jlpt":"N3"},
        {"kanji":"星", "stroke":9, "grade":2, "jlpt":"N3"},
        {"kanji":"牲", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"省", "stroke":9, "grade":4, "jlpt":"N3"},
        {"kanji":"凄", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"逝", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"清", "stroke":11, "grade":4, "jlpt":"N2"},
        {"kanji":"盛", "stroke":11, "grade":6, "jlpt":"N2"},
        {"kanji":"婿", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"晴", "stroke":12, "grade":2, "jlpt":"N3"},
        {"kanji":"勢", "stroke":13, "grade":5, "jlpt":"N2"},
        {"kanji":"聖", "stroke":13, "grade":6, "jlpt":"N1"},
        {"kanji":"誠", "stroke":13, "grade":6, "jlpt":"N1"},
        {"kanji":"精", "stroke":14, "grade":5, "jlpt":"N2"},
        {"kanji":"製", "stroke":14, "grade":5, "jlpt":"N3"},
        {"kanji":"誓", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"静", "stroke":14, "grade":4, "jlpt":"N3"},
        {"kanji":"請", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"整", "stroke":16, "grade":3, "jlpt":"N3"},
        {"kanji":"醒", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"税", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"夕", "stroke":3, "grade":1, "jlpt":"N4"},
        {"kanji":"斥", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"石", "stroke":5, "grade":1, "jlpt":"N4"},
        {"kanji":"赤", "stroke":7, "grade":1, "jlpt":"N4"},
        {"kanji":"昔", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"析", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"席", "stroke":10, "grade":4, "jlpt":"N3"},
        {"kanji":"脊", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"隻", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"惜", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"戚", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"責", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"跡", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"積", "stroke":16, "grade":4, "jlpt":"N2"},
        {"kanji":"績", "stroke":17, "grade":5, "jlpt":"N2"},
        {"kanji":"籍", "stroke":20, "grade":7, "jlpt":"N2"},
        {"kanji":"切", "stroke":4, "grade":2, "jlpt":"N4"},
        {"kanji":"折", "stroke":7, "grade":4, "jlpt":"N3"},
        {"kanji":"拙", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"窃", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"接", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"設", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"雪", "stroke":11, "grade":2, "jlpt":"N3"},
        {"kanji":"摂", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"節", "stroke":13, "grade":4, "jlpt":"N2"},
        {"kanji":"説", "stroke":14, "grade":4, "jlpt":"N4"},
        {"kanji":"舌", "stroke":6, "grade":6, "jlpt":"N2"},
        {"kanji":"絶", "stroke":12, "grade":5, "jlpt":"N2"},
        {"kanji":"千", "stroke":3, "grade":1, "jlpt":"N5"},
        {"kanji":"川", "stroke":3, "grade":1, "jlpt":"N5"},
        {"kanji":"仙", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"占", "stroke":5, "grade":7, "jlpt":"N2"},
        {"kanji":"先", "stroke":6, "grade":1, "jlpt":"N5"},
        {"kanji":"宣", "stroke":9, "grade":6, "jlpt":"N1"},
        {"kanji":"専", "stroke":9, "grade":6, "jlpt":"N3"},
        {"kanji":"泉", "stroke":9, "grade":6, "jlpt":"N3"},
        {"kanji":"浅", "stroke":9, "grade":4, "jlpt":"N2"},
        {"kanji":"洗", "stroke":9, "grade":6, "jlpt":"N4"},
        {"kanji":"染", "stroke":9, "grade":6, "jlpt":"N2"},
        {"kanji":"扇", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"栓", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"旋", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"船", "stroke":11, "grade":2, "jlpt":"N3"},
        {"kanji":"戦", "stroke":13, "grade":4, "jlpt":"N3"},
        {"kanji":"煎", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"羨", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"腺", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"詮", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"践", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"箋", "stroke":14, "grade":7, "jlpt":"-"},
        {"kanji":"銭", "stroke":14, "grade":6, "jlpt":"N1"},
        {"kanji":"潜", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"線", "stroke":15, "grade":2, "jlpt":"N3"},
        {"kanji":"遷", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"選", "stroke":15, "grade":4, "jlpt":"N3"},
        {"kanji":"薦", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"繊", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"鮮", "stroke":17, "grade":7, "jlpt":"N2"},
        {"kanji":"全", "stroke":6, "grade":3, "jlpt":"N4"},
        {"kanji":"前", "stroke":9, "grade":2, "jlpt":"N5"},
        {"kanji":"善", "stroke":12, "grade":6, "jlpt":"N2"},
        {"kanji":"然", "stroke":12, "grade":4, "jlpt":"N3"},
        {"kanji":"禅", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"漸", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"膳", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"繕", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"狙", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"阻", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"祖", "stroke":9, "grade":5, "jlpt":"N2"},
        {"kanji":"租", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"素", "stroke":10, "grade":5, "jlpt":"N2"},
        {"kanji":"措", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"粗", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"組", "stroke":11, "grade":2, "jlpt":"N3"},
        {"kanji":"疎", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"訴", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"塑", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"遡", "stroke":14, "grade":7, "jlpt":"-"},
        {"kanji":"礎", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"双", "stroke":4, "grade":7, "jlpt":"N2"},
        {"kanji":"壮", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"早", "stroke":6, "grade":1, "jlpt":"N5"},
        {"kanji":"争", "stroke":6, "grade":4, "jlpt":"N3"},
        {"kanji":"走", "stroke":7, "grade":2, "jlpt":"N4"},
        {"kanji":"奏", "stroke":9, "grade":6, "jlpt":"N1"},
        {"kanji":"相", "stroke":9, "grade":3, "jlpt":"N3"},
        {"kanji":"荘", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"草", "stroke":9, "grade":1, "jlpt":"N3"},
        {"kanji":"送", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"倉", "stroke":10, "grade":4, "jlpt":"N1"},
        {"kanji":"捜", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"挿", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"桑", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"巣", "stroke":11, "grade":4, "jlpt":"N1"},
        {"kanji":"掃", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"曹", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"曽", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"爽", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"窓", "stroke":11, "grade":6, "jlpt":"N3"},
        {"kanji":"創", "stroke":12, "grade":6, "jlpt":"N1"},
        {"kanji":"喪", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"痩", "stroke":12, "grade":7, "jlpt":"-"},
        {"kanji":"葬", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"装", "stroke":12, "grade":6, "jlpt":"N2"},
        {"kanji":"僧", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"想", "stroke":13, "grade":3, "jlpt":"N3"},
        {"kanji":"層", "stroke":14, "grade":6, "jlpt":"N2"},
        {"kanji":"総", "stroke":14, "grade":5, "jlpt":"N2"},
        {"kanji":"遭", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"槽", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"踪", "stroke":15, "grade":7, "jlpt":"-"},
        {"kanji":"操", "stroke":16, "grade":6, "jlpt":"N2"},
        {"kanji":"燥", "stroke":17, "grade":7, "jlpt":"N2"},
        {"kanji":"霜", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"騒", "stroke":18, "grade":7, "jlpt":"N2"},
        {"kanji":"藻", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"造", "stroke":10, "grade":5, "jlpt":"N3"},
        {"kanji":"像", "stroke":14, "grade":5, "jlpt":"N2"},
        {"kanji":"増", "stroke":14, "grade":5, "jlpt":"N3"},
        {"kanji":"憎", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"蔵", "stroke":15, "grade":6, "jlpt":"N3"},
        {"kanji":"贈", "stroke":18, "grade":7, "jlpt":"N2"},
        {"kanji":"臓", "stroke":19, "grade":6, "jlpt":"N2"},
        {"kanji":"即", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"束", "stroke":7, "grade":4, "jlpt":"N3"},
        {"kanji":"足", "stroke":7, "grade":1, "jlpt":"N5"},
        {"kanji":"促", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"則", "stroke":9, "grade":5, "jlpt":"N3"},
        {"kanji":"息", "stroke":10, "grade":3, "jlpt":"N3"},
        {"kanji":"捉", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"速", "stroke":10, "grade":3, "jlpt":"N3"},
        {"kanji":"側", "stroke":11, "grade":4, "jlpt":"N3"},
        {"kanji":"測", "stroke":12, "grade":5, "jlpt":"N2"},
        {"kanji":"俗", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"族", "stroke":11, "grade":3, "jlpt":"N4"},
        {"kanji":"属", "stroke":12, "grade":5, "jlpt":"N2"},
        {"kanji":"賊", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"続", "stroke":13, "grade":4, "jlpt":"N3"},
        {"kanji":"卒", "stroke":8, "grade":4, "jlpt":"N3"},
        {"kanji":"率", "stroke":11, "grade":5, "jlpt":"N2"},
        {"kanji":"存", "stroke":6, "grade":6, "jlpt":"N3"},
        {"kanji":"村", "stroke":7, "grade":1, "jlpt":"N4"},
        {"kanji":"孫", "stroke":10, "grade":4, "jlpt":"N2"},
        {"kanji":"尊", "stroke":12, "grade":6, "jlpt":"N2"},
        {"kanji":"損", "stroke":13, "grade":5, "jlpt":"N2"},
        {"kanji":"遜", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"他", "stroke":5, "grade":3, "jlpt":"N3"},
        {"kanji":"多", "stroke":6, "grade":2, "jlpt":"N5"},
        {"kanji":"汰", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"打", "stroke":5, "grade":3, "jlpt":"N3"},
        {"kanji":"妥", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"唾", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"堕", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"惰", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"駄", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"太", "stroke":4, "grade":2, "jlpt":"N4"},
        {"kanji":"対", "stroke":7, "grade":3, "jlpt":"N3"},
        {"kanji":"体", "stroke":7, "grade":2, "jlpt":"N4"},
        {"kanji":"耐", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"待", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"怠", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"胎", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"退", "stroke":9, "grade":6, "jlpt":"N3"},
        {"kanji":"帯", "stroke":10, "grade":4, "jlpt":"N3"},
        {"kanji":"泰", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"堆", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"袋", "stroke":11, "grade":7, "jlpt":"N3"},
        {"kanji":"逮", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"替", "stroke":12, "grade":7, "jlpt":"N3"},
        {"kanji":"貸", "stroke":12, "grade":5, "jlpt":"N4"},
        {"kanji":"隊", "stroke":12, "grade":4, "jlpt":"N1"},
        {"kanji":"滞", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"態", "stroke":14, "grade":5, "jlpt":"N2"},
        {"kanji":"戴", "stroke":17, "grade":7, "jlpt":"-"},
        {"kanji":"大", "stroke":3, "grade":1, "jlpt":"N5"},
        {"kanji":"代", "stroke":5, "grade":3, "jlpt":"N4"},
        {"kanji":"台", "stroke":5, "grade":2, "jlpt":"N4"},
        {"kanji":"第", "stroke":11, "grade":3, "jlpt":"N3"},
        {"kanji":"題", "stroke":18, "grade":3, "jlpt":"N4"},
        {"kanji":"滝", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"宅", "stroke":6, "grade":6, "jlpt":"N3"},
        {"kanji":"択", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"沢", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"卓", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"拓", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"託", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"濯", "stroke":17, "grade":7, "jlpt":"N4"},
        {"kanji":"諾", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"濁", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"但", "stroke":7, "grade":7, "jlpt":"-"},
        {"kanji":"達", "stroke":12, "grade":4, "jlpt":"N3"},
        {"kanji":"脱", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"奪", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"棚", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"誰", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"丹", "stroke":4, "grade":7, "jlpt":"N1"},
        {"kanji":"旦", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"担", "stroke":8, "grade":6, "jlpt":"N2"},
        {"kanji":"単", "stroke":9, "grade":4, "jlpt":"N3"},
        {"kanji":"炭", "stroke":9, "grade":3, "jlpt":"N2"},
        {"kanji":"胆", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"探", "stroke":11, "grade":6, "jlpt":"N2"},
        {"kanji":"淡", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"短", "stroke":12, "grade":3, "jlpt":"N4"},
        {"kanji":"嘆", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"端", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"綻", "stroke":14, "grade":7, "jlpt":"-"},
        {"kanji":"誕", "stroke":15, "grade":6, "jlpt":"N2"},
        {"kanji":"鍛", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"団", "stroke":6, "grade":5, "jlpt":"N3"},
        {"kanji":"男", "stroke":7, "grade":1, "jlpt":"N5"},
        {"kanji":"段", "stroke":9, "grade":6, "jlpt":"N3"},
        {"kanji":"断", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"弾", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"暖", "stroke":13, "grade":6, "jlpt":"N3"},
        {"kanji":"談", "stroke":15, "grade":3, "jlpt":"N3"},
        {"kanji":"壇", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"地", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"池", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"知", "stroke":8, "grade":2, "jlpt":"N4"},
        {"kanji":"値", "stroke":10, "grade":6, "jlpt":"N3"},
        {"kanji":"恥", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"致", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"遅", "stroke":12, "grade":7, "jlpt":"N3"},
        {"kanji":"痴", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"稚", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"置", "stroke":13, "grade":4, "jlpt":"N3"},
        {"kanji":"緻", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"竹", "stroke":6, "grade":1, "jlpt":"N2"},
        {"kanji":"畜", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"逐", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"蓄", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"築", "stroke":16, "grade":5, "jlpt":"N2"},
        {"kanji":"秩", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"窒", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"茶", "stroke":9, "grade":2, "jlpt":"N4"},
        {"kanji":"着", "stroke":12, "grade":3, "jlpt":"N4"},
        {"kanji":"嫡", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"中", "stroke":4, "grade":1, "jlpt":"N5"},
        {"kanji":"仲", "stroke":6, "grade":4, "jlpt":"N2"},
        {"kanji":"虫", "stroke":6, "grade":1, "jlpt":"N4"},
        {"kanji":"沖", "stroke":7, "grade":4, "jlpt":"N1"},
        {"kanji":"宙", "stroke":8, "grade":6, "jlpt":"N2"},
        {"kanji":"忠", "stroke":8, "grade":6, "jlpt":"N1"},
        {"kanji":"抽", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"注", "stroke":8, "grade":3, "jlpt":"N4"},
        {"kanji":"昼", "stroke":9, "grade":2, "jlpt":"N4"},
        {"kanji":"柱", "stroke":9, "grade":3, "jlpt":"N2"},
        {"kanji":"衷", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"酎", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"鋳", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"駐", "stroke":15, "grade":7, "jlpt":"N3"},
        {"kanji":"著", "stroke":11, "grade":6, "jlpt":"N2"},
        {"kanji":"貯", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"丁", "stroke":2, "grade":3, "jlpt":"N2"},
        {"kanji":"弔", "stroke":4, "grade":7, "jlpt":"N1"},
        {"kanji":"庁", "stroke":5, "grade":6, "jlpt":"N2"},
        {"kanji":"兆", "stroke":6, "grade":4, "jlpt":"N2"},
        {"kanji":"町", "stroke":7, "grade":1, "jlpt":"N4"},
        {"kanji":"長", "stroke":8, "grade":2, "jlpt":"N5"},
        {"kanji":"挑", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"帳", "stroke":11, "grade":3, "jlpt":"N2"},
        {"kanji":"張", "stroke":11, "grade":5, "jlpt":"N2"},
        {"kanji":"彫", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"眺", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"釣", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"頂", "stroke":11, "grade":6, "jlpt":"N2"},
        {"kanji":"鳥", "stroke":11, "grade":2, "jlpt":"N4"},
        {"kanji":"朝", "stroke":12, "grade":2, "jlpt":"N4"},
        {"kanji":"貼", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"超", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"腸", "stroke":13, "grade":6, "jlpt":"N1"},
        {"kanji":"跳", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"徴", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"嘲", "stroke":15, "grade":7, "jlpt":"-"},
        {"kanji":"潮", "stroke":15, "grade":6, "jlpt":"N1"},
        {"kanji":"澄", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"調", "stroke":15, "grade":3, "jlpt":"N3"},
        {"kanji":"聴", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"懲", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"直", "stroke":8, "grade":2, "jlpt":"N3"},
        {"kanji":"勅", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"捗", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"沈", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"珍", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"朕", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"陳", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"賃", "stroke":13, "grade":6, "jlpt":"N2"},
        {"kanji":"鎮", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"追", "stroke":9, "grade":3, "jlpt":"N3"},
        {"kanji":"椎", "stroke":12, "grade":7, "jlpt":"-"},
        {"kanji":"墜", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"通", "stroke":10, "grade":2, "jlpt":"N4"},
        {"kanji":"痛", "stroke":12, "grade":6, "jlpt":"N3"},
        {"kanji":"塚", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"漬", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"坪", "stroke":8, "grade":7, "jlpt":"-"},
        {"kanji":"爪", "stroke":4, "grade":7, "jlpt":"N1"},
        {"kanji":"鶴", "stroke":21, "grade":7, "jlpt":"N1"},
        {"kanji":"低", "stroke":7, "grade":4, "jlpt":"N4"},
        {"kanji":"呈", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"廷", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"弟", "stroke":7, "grade":2, "jlpt":"N4"},
        {"kanji":"定", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"底", "stroke":8, "grade":4, "jlpt":"N2"},
        {"kanji":"抵", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"邸", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"亭", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"貞", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"帝", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"訂", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"庭", "stroke":10, "grade":3, "jlpt":"N3"},
        {"kanji":"逓", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"停", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"偵", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"堤", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"提", "stroke":12, "grade":5, "jlpt":"N2"},
        {"kanji":"程", "stroke":12, "grade":5, "jlpt":"N2"},
        {"kanji":"艇", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"締", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"諦", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"泥", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"的", "stroke":8, "grade":4, "jlpt":"N3"},
        {"kanji":"笛", "stroke":11, "grade":3, "jlpt":"N1"},
        {"kanji":"摘", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"滴", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"適", "stroke":14, "grade":5, "jlpt":"N3"},
        {"kanji":"敵", "stroke":15, "grade":6, "jlpt":"N2"},
        {"kanji":"溺", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"迭", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"哲", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"鉄", "stroke":13, "grade":3, "jlpt":"N4"},
        {"kanji":"徹", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"撤", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"天", "stroke":4, "grade":1, "jlpt":"N5"},
        {"kanji":"典", "stroke":8, "grade":4, "jlpt":"N2"},
        {"kanji":"店", "stroke":8, "grade":2, "jlpt":"N5"},
        {"kanji":"点", "stroke":9, "grade":2, "jlpt":"N3"},
        {"kanji":"展", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"添", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"転", "stroke":11, "grade":3, "jlpt":"N4"},
        {"kanji":"塡", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"田", "stroke":5, "grade":1, "jlpt":"N5"},
        {"kanji":"伝", "stroke":6, "grade":4, "jlpt":"N3"},
        {"kanji":"殿", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"電", "stroke":13, "grade":2, "jlpt":"N5"},
        {"kanji":"斗", "stroke":4, "grade":7, "jlpt":"-"},
        {"kanji":"吐", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"妬", "stroke":8, "grade":7, "jlpt":"-"},
        {"kanji":"徒", "stroke":10, "grade":4, "jlpt":"N2"},
        {"kanji":"途", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"都", "stroke":11, "grade":3, "jlpt":"N4"},
        {"kanji":"渡", "stroke":12, "grade":7, "jlpt":"N3"},
        {"kanji":"塗", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"賭", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"土", "stroke":3, "grade":1, "jlpt":"N5"},
        {"kanji":"奴", "stroke":5, "grade":7, "jlpt":"-"},
        {"kanji":"努", "stroke":7, "grade":4, "jlpt":"N2"},
        {"kanji":"度", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"怒", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"刀", "stroke":2, "grade":2, "jlpt":"N1"},
        {"kanji":"冬", "stroke":5, "grade":2, "jlpt":"N4"},
        {"kanji":"灯", "stroke":6, "grade":4, "jlpt":"N2"},
        {"kanji":"当", "stroke":6, "grade":2, "jlpt":"N3"},
        {"kanji":"投", "stroke":7, "grade":3, "jlpt":"N3"},
        {"kanji":"豆", "stroke":7, "grade":3, "jlpt":"N2"},
        {"kanji":"東", "stroke":8, "grade":2, "jlpt":"N5"},
        {"kanji":"到", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"逃", "stroke":9, "grade":7, "jlpt":"N3"},
        {"kanji":"倒", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"凍", "stroke":10, "grade":7, "jlpt":"N3"},
        {"kanji":"唐", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"島", "stroke":10, "grade":3, "jlpt":"N4"},
        {"kanji":"桃", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"討", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"透", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"党", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"悼", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"盗", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"陶", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"塔", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"搭", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"棟", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"湯", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"痘", "stroke":12, "grade":7, "jlpt":"-"},
        {"kanji":"登", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"答", "stroke":12, "grade":2, "jlpt":"N4"},
        {"kanji":"等", "stroke":12, "grade":3, "jlpt":"N2"},
        {"kanji":"筒", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"統", "stroke":12, "grade":5, "jlpt":"N2"},
        {"kanji":"稲", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"踏", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"糖", "stroke":16, "grade":6, "jlpt":"N2"},
        {"kanji":"頭", "stroke":16, "grade":2, "jlpt":"N4"},
        {"kanji":"謄", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"藤", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"闘", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"騰", "stroke":20, "grade":7, "jlpt":"N1"},
        {"kanji":"同", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"洞", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"胴", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"動", "stroke":11, "grade":3, "jlpt":"N4"},
        {"kanji":"堂", "stroke":11, "grade":5, "jlpt":"N4"},
        {"kanji":"童", "stroke":12, "grade":3, "jlpt":"N2"},
        {"kanji":"道", "stroke":12, "grade":2, "jlpt":"N5"},
        {"kanji":"働", "stroke":13, "grade":4, "jlpt":"N4"},
        {"kanji":"銅", "stroke":14, "grade":5, "jlpt":"N2"},
        {"kanji":"導", "stroke":15, "grade":5, "jlpt":"N2"},
        {"kanji":"瞳", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"峠", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"匿", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"特", "stroke":10, "grade":4, "jlpt":"N4"},
        {"kanji":"得", "stroke":11, "grade":5, "jlpt":"N2"},
        {"kanji":"督", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"徳", "stroke":14, "grade":4, "jlpt":"N1"},
        {"kanji":"篤", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"毒", "stroke":8, "grade":5, "jlpt":"N2"},
        {"kanji":"独", "stroke":9, "grade":5, "jlpt":"N2"},
        {"kanji":"読", "stroke":14, "grade":2, "jlpt":"N5"},
        {"kanji":"栃", "stroke":9, "grade":4, "jlpt":"-"},
        {"kanji":"凸", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"突", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"届", "stroke":8, "grade":6, "jlpt":"N3"},
        {"kanji":"屯", "stroke":4, "grade":7, "jlpt":"N1"},
        {"kanji":"豚", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"頓", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"貪", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"鈍", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"曇", "stroke":16, "grade":7, "jlpt":"N2"},
        {"kanji":"丼", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"那", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"奈", "stroke":8, "grade":4, "jlpt":"-"},
        {"kanji":"内", "stroke":4, "grade":2, "jlpt":"N4"},
        {"kanji":"梨", "stroke":11, "grade":4, "jlpt":"-"},
        {"kanji":"謎", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"鍋", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"南", "stroke":9, "grade":2, "jlpt":"N5"},
        {"kanji":"軟", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"難", "stroke":18, "grade":6, "jlpt":"N3"},
        {"kanji":"二", "stroke":2, "grade":1, "jlpt":"N5"},
        {"kanji":"尼", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"弐", "stroke":6, "grade":7, "jlpt":"-"},
        {"kanji":"匂", "stroke":4, "grade":7, "jlpt":"N1"},
        {"kanji":"肉", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"虹", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"日", "stroke":4, "grade":1, "jlpt":"N5"},
        {"kanji":"入", "stroke":2, "grade":1, "jlpt":"N5"},
        {"kanji":"乳", "stroke":8, "grade":6, "jlpt":"N3"},
        {"kanji":"尿", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"任", "stroke":6, "grade":5, "jlpt":"N3"},
        {"kanji":"妊", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"忍", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"認", "stroke":14, "grade":6, "jlpt":"N3"},
        {"kanji":"寧", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"熱", "stroke":15, "grade":4, "jlpt":"N3"},
        {"kanji":"年", "stroke":6, "grade":1, "jlpt":"N5"},
        {"kanji":"念", "stroke":8, "grade":4, "jlpt":"N3"},
        {"kanji":"捻", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"粘", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"燃", "stroke":16, "grade":5, "jlpt":"N2"},
        {"kanji":"悩", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"納", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"能", "stroke":10, "grade":5, "jlpt":"N3"},
        {"kanji":"脳", "stroke":11, "grade":6, "jlpt":"N2"},
        {"kanji":"農", "stroke":13, "grade":3, "jlpt":"N3"},
        {"kanji":"濃", "stroke":16, "grade":7, "jlpt":"N3"},
        {"kanji":"把", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"波", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"派", "stroke":9, "grade":6, "jlpt":"N2"},
        {"kanji":"破", "stroke":10, "grade":5, "jlpt":"N2"},
        {"kanji":"覇", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"馬", "stroke":10, "grade":2, "jlpt":"N3"},
        {"kanji":"婆", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"罵", "stroke":15, "grade":7, "jlpt":"-"},
        {"kanji":"拝", "stroke":8, "grade":6, "jlpt":"N2"},
        {"kanji":"杯", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"背", "stroke":9, "grade":6, "jlpt":"N2"},
        {"kanji":"肺", "stroke":9, "grade":6, "jlpt":"N1"},
        {"kanji":"俳", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"配", "stroke":10, "grade":3, "jlpt":"N3"},
        {"kanji":"排", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"敗", "stroke":11, "grade":4, "jlpt":"N3"},
        {"kanji":"廃", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"輩", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"売", "stroke":7, "grade":2, "jlpt":"N4"},
        {"kanji":"倍", "stroke":10, "grade":3, "jlpt":"N3"},
        {"kanji":"梅", "stroke":10, "grade":4, "jlpt":"N1"},
        {"kanji":"培", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"陪", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"媒", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"買", "stroke":12, "grade":2, "jlpt":"N5"},
        {"kanji":"賠", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"白", "stroke":5, "grade":1, "jlpt":"N5"},
        {"kanji":"伯", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"拍", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"泊", "stroke":8, "grade":7, "jlpt":"N3"},
        {"kanji":"迫", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"剝", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"舶", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"博", "stroke":12, "grade":4, "jlpt":"N2"},
        {"kanji":"薄", "stroke":16, "grade":7, "jlpt":"N3"},
        {"kanji":"麦", "stroke":7, "grade":2, "jlpt":"N2"},
        {"kanji":"漠", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"縛", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"爆", "stroke":19, "grade":7, "jlpt":"N2"},
        {"kanji":"箱", "stroke":15, "grade":3, "jlpt":"N3"},
        {"kanji":"箸", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"畑", "stroke":9, "grade":3, "jlpt":"N2"},
        {"kanji":"肌", "stroke":6, "grade":7, "jlpt":"N2"},
        {"kanji":"八", "stroke":2, "grade":1, "jlpt":"N5"},
        {"kanji":"鉢", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"発", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"髪", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"伐", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"抜", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"罰", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"閥", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"反", "stroke":4, "grade":3, "jlpt":"N3"},
        {"kanji":"半", "stroke":5, "grade":2, "jlpt":"N5"},
        {"kanji":"氾", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"犯", "stroke":5, "grade":5, "jlpt":"N2"},
        {"kanji":"帆", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"汎", "stroke":6, "grade":7, "jlpt":"-"},
        {"kanji":"伴", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"判", "stroke":7, "grade":5, "jlpt":"N3"},
        {"kanji":"坂", "stroke":7, "grade":3, "jlpt":"N2"},
        {"kanji":"阪", "stroke":7, "grade":4, "jlpt":"-"},
        {"kanji":"板", "stroke":8, "grade":3, "jlpt":"N2"},
        {"kanji":"版", "stroke":8, "grade":5, "jlpt":"N2"},
        {"kanji":"班", "stroke":10, "grade":6, "jlpt":"N1"},
        {"kanji":"畔", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"般", "stroke":10, "grade":7, "jlpt":"N3"},
        {"kanji":"販", "stroke":11, "grade":7, "jlpt":"N3"},
        {"kanji":"斑", "stroke":12, "grade":7, "jlpt":"-"},
        {"kanji":"飯", "stroke":12, "grade":4, "jlpt":"N4"},
        {"kanji":"搬", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"煩", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"頒", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"範", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"繁", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"藩", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"晩", "stroke":12, "grade":6, "jlpt":"N4"},
        {"kanji":"番", "stroke":12, "grade":2, "jlpt":"N4"},
        {"kanji":"蛮", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"盤", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"比", "stroke":4, "grade":5, "jlpt":"N3"},
        {"kanji":"皮", "stroke":5, "grade":3, "jlpt":"N2"},
        {"kanji":"妃", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"否", "stroke":7, "grade":6, "jlpt":"N3"},
        {"kanji":"批", "stroke":7, "grade":6, "jlpt":"N2"},
        {"kanji":"彼", "stroke":8, "grade":7, "jlpt":"N3"},
        {"kanji":"披", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"肥", "stroke":8, "grade":5, "jlpt":"N1"},
        {"kanji":"非", "stroke":8, "grade":5, "jlpt":"N3"},
        {"kanji":"卑", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"飛", "stroke":9, "grade":4, "jlpt":"N3"},
        {"kanji":"疲", "stroke":10, "grade":7, "jlpt":"N4"},
        {"kanji":"秘", "stroke":10, "grade":6, "jlpt":"N2"},
        {"kanji":"被", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"悲", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"扉", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"費", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"碑", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"罷", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"避", "stroke":16, "grade":7, "jlpt":"N2"},
        {"kanji":"尾", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"眉", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"美", "stroke":9, "grade":3, "jlpt":"N3"},
        {"kanji":"備", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"微", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"鼻", "stroke":14, "grade":3, "jlpt":"N3"},
        {"kanji":"膝", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"肘", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"匹", "stroke":4, "grade":7, "jlpt":"N2"},
        {"kanji":"必", "stroke":5, "grade":4, "jlpt":"N3"},
        {"kanji":"泌", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"筆", "stroke":12, "grade":3, "jlpt":"N2"},
        {"kanji":"姫", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"百", "stroke":6, "grade":1, "jlpt":"N5"},
        {"kanji":"氷", "stroke":5, "grade":3, "jlpt":"N3"},
        {"kanji":"表", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"俵", "stroke":10, "grade":6, "jlpt":"N1"},
        {"kanji":"票", "stroke":11, "grade":4, "jlpt":"N2"},
        {"kanji":"評", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"漂", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"標", "stroke":15, "grade":4, "jlpt":"N2"},
        {"kanji":"苗", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"秒", "stroke":9, "grade":3, "jlpt":"N3"},
        {"kanji":"病", "stroke":10, "grade":3, "jlpt":"N4"},
        {"kanji":"描", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"猫", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"品", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"浜", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"貧", "stroke":11, "grade":5, "jlpt":"N2"},
        {"kanji":"賓", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"頻", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"敏", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"瓶", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"不", "stroke":4, "grade":4, "jlpt":"N4"},
        {"kanji":"夫", "stroke":4, "grade":4, "jlpt":"N3"},
        {"kanji":"父", "stroke":4, "grade":2, "jlpt":"N5"},
        {"kanji":"付", "stroke":5, "grade":4, "jlpt":"N3"},
        {"kanji":"布", "stroke":5, "grade":5, "jlpt":"N2"},
        {"kanji":"扶", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"府", "stroke":8, "grade":4, "jlpt":"N4"},
        {"kanji":"怖", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"阜", "stroke":8, "grade":4, "jlpt":"-"},
        {"kanji":"附", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"訃", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"負", "stroke":9, "grade":3, "jlpt":"N3"},
        {"kanji":"赴", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"浮", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"婦", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"符", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"富", "stroke":12, "grade":4, "jlpt":"N2"},
        {"kanji":"普", "stroke":12, "grade":7, "jlpt":"N3"},
        {"kanji":"腐", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"敷", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"膚", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"賦", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"譜", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"侮", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"武", "stroke":8, "grade":5, "jlpt":"N2"},
        {"kanji":"部", "stroke":11, "grade":3, "jlpt":"N4"},
        {"kanji":"舞", "stroke":15, "grade":7, "jlpt":"N2"},
        {"kanji":"封", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"風", "stroke":9, "grade":2, "jlpt":"N4"},
        {"kanji":"伏", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"服", "stroke":8, "grade":3, "jlpt":"N4"},
        {"kanji":"副", "stroke":11, "grade":4, "jlpt":"N2"},
        {"kanji":"幅", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"復", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"福", "stroke":13, "grade":3, "jlpt":"N2"},
        {"kanji":"腹", "stroke":13, "grade":6, "jlpt":"N2"},
        {"kanji":"複", "stroke":14, "grade":5, "jlpt":"N3"},
        {"kanji":"覆", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"払", "stroke":5, "grade":7, "jlpt":"N3"},
        {"kanji":"沸", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"仏", "stroke":4, "grade":5, "jlpt":"N2"},
        {"kanji":"物", "stroke":8, "grade":3, "jlpt":"N4"},
        {"kanji":"粉", "stroke":10, "grade":5, "jlpt":"N3"},
        {"kanji":"紛", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"雰", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"噴", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"墳", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"憤", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"奮", "stroke":16, "grade":6, "jlpt":"N1"},
        {"kanji":"分", "stroke":4, "grade":2, "jlpt":"N5"},
        {"kanji":"文", "stroke":4, "grade":1, "jlpt":"N4"},
        {"kanji":"聞", "stroke":14, "grade":2, "jlpt":"N5"},
        {"kanji":"丙", "stroke":5, "grade":7, "jlpt":"-"},
        {"kanji":"平", "stroke":5, "grade":3, "jlpt":"N3"},
        {"kanji":"兵", "stroke":7, "grade":4, "jlpt":"N2"},
        {"kanji":"併", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"並", "stroke":8, "grade":6, "jlpt":"N3"},
        {"kanji":"柄", "stroke":9, "grade":7, "jlpt":"N2"},
        {"kanji":"陛", "stroke":10, "grade":6, "jlpt":"N1"},
        {"kanji":"閉", "stroke":11, "grade":6, "jlpt":"N4"},
        {"kanji":"塀", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"幣", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"弊", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"蔽", "stroke":15, "grade":7, "jlpt":"-"},
        {"kanji":"餅", "stroke":15, "grade":7, "jlpt":"-"},
        {"kanji":"米", "stroke":6, "grade":2, "jlpt":"N4"},
        {"kanji":"壁", "stroke":16, "grade":7, "jlpt":"N2"},
        {"kanji":"璧", "stroke":18, "grade":7, "jlpt":"-"},
        {"kanji":"癖", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"別", "stroke":7, "grade":4, "jlpt":"N4"},
        {"kanji":"蔑", "stroke":14, "grade":7, "jlpt":"-"},
        {"kanji":"片", "stroke":4, "grade":6, "jlpt":"N2"},
        {"kanji":"辺", "stroke":5, "grade":4, "jlpt":"N3"},
        {"kanji":"返", "stroke":7, "grade":3, "jlpt":"N4"},
        {"kanji":"変", "stroke":9, "grade":4, "jlpt":"N3"},
        {"kanji":"偏", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"遍", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"編", "stroke":15, "grade":5, "jlpt":"N2"},
        {"kanji":"弁", "stroke":5, "grade":5, "jlpt":"N1"},
        {"kanji":"便", "stroke":9, "grade":4, "jlpt":"N4"},
        {"kanji":"勉", "stroke":10, "grade":3, "jlpt":"N4"},
        {"kanji":"歩", "stroke":8, "grade":2, "jlpt":"N4"},
        {"kanji":"保", "stroke":9, "grade":5, "jlpt":"N3"},
        {"kanji":"哺", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"捕", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"補", "stroke":12, "grade":6, "jlpt":"N2"},
        {"kanji":"舗", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"母", "stroke":5, "grade":2, "jlpt":"N5"},
        {"kanji":"募", "stroke":12, "grade":7, "jlpt":"N3"},
        {"kanji":"墓", "stroke":13, "grade":5, "jlpt":"N1"},
        {"kanji":"慕", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"暮", "stroke":14, "grade":6, "jlpt":"N2"},
        {"kanji":"簿", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"方", "stroke":4, "grade":2, "jlpt":"N4"},
        {"kanji":"包", "stroke":5, "grade":4, "jlpt":"N2"},
        {"kanji":"芳", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"邦", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"奉", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"宝", "stroke":8, "grade":6, "jlpt":"N2"},
        {"kanji":"抱", "stroke":8, "grade":7, "jlpt":"N2"},
        {"kanji":"放", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"法", "stroke":8, "grade":4, "jlpt":"N4"},
        {"kanji":"泡", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"胞", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"俸", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"倣", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"峰", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"砲", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"崩", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"訪", "stroke":11, "grade":6, "jlpt":"N3"},
        {"kanji":"報", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"蜂", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"豊", "stroke":13, "grade":5, "jlpt":"N2"},
        {"kanji":"飽", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"褒", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"縫", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"亡", "stroke":3, "grade":6, "jlpt":"N3"},
        {"kanji":"乏", "stroke":4, "grade":7, "jlpt":"N2"},
        {"kanji":"忙", "stroke":6, "grade":7, "jlpt":"N3"},
        {"kanji":"坊", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"妨", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"忘", "stroke":7, "grade":6, "jlpt":"N3"},
        {"kanji":"防", "stroke":7, "grade":5, "jlpt":"N3"},
        {"kanji":"房", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"肪", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"某", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"冒", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"剖", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"紡", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"望", "stroke":11, "grade":4, "jlpt":"N3"},
        {"kanji":"傍", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"帽", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"棒", "stroke":12, "grade":6, "jlpt":"N2"},
        {"kanji":"貿", "stroke":12, "grade":5, "jlpt":"N3"},
        {"kanji":"貌", "stroke":14, "grade":7, "jlpt":"-"},
        {"kanji":"暴", "stroke":15, "grade":5, "jlpt":"N2"},
        {"kanji":"膨", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"謀", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"頰", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"北", "stroke":5, "grade":2, "jlpt":"N5"},
        {"kanji":"木", "stroke":4, "grade":1, "jlpt":"N5"},
        {"kanji":"朴", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"牧", "stroke":8, "grade":4, "jlpt":"N1"},
        {"kanji":"睦", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"僕", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"墨", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"撲", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"没", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"勃", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"堀", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"本", "stroke":5, "grade":1, "jlpt":"N5"},
        {"kanji":"奔", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"翻", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"凡", "stroke":3, "grade":7, "jlpt":"N1"},
        {"kanji":"盆", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"麻", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"摩", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"磨", "stroke":16, "grade":7, "jlpt":"N2"},
        {"kanji":"魔", "stroke":21, "grade":7, "jlpt":"N1"},
        {"kanji":"毎", "stroke":6, "grade":2, "jlpt":"N5"},
        {"kanji":"妹", "stroke":8, "grade":2, "jlpt":"N4"},
        {"kanji":"枚", "stroke":8, "grade":6, "jlpt":"N3"},
        {"kanji":"昧", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"埋", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"幕", "stroke":13, "grade":6, "jlpt":"N2"},
        {"kanji":"膜", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"枕", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"又", "stroke":2, "grade":7, "jlpt":"N1"},
        {"kanji":"末", "stroke":5, "grade":4, "jlpt":"N3"},
        {"kanji":"抹", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"万", "stroke":3, "grade":2, "jlpt":"N5"},
        {"kanji":"満", "stroke":12, "grade":4, "jlpt":"N3"},
        {"kanji":"慢", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"漫", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"未", "stroke":5, "grade":4, "jlpt":"N3"},
        {"kanji":"味", "stroke":8, "grade":3, "jlpt":"N4"},
        {"kanji":"魅", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"岬", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"密", "stroke":11, "grade":6, "jlpt":"N2"},
        {"kanji":"蜜", "stroke":14, "grade":7, "jlpt":"-"},
        {"kanji":"脈", "stroke":10, "grade":5, "jlpt":"N2"},
        {"kanji":"妙", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"民", "stroke":5, "grade":4, "jlpt":"N4"},
        {"kanji":"眠", "stroke":10, "grade":7, "jlpt":"N3"},
        {"kanji":"矛", "stroke":5, "grade":7, "jlpt":"N1"},
        {"kanji":"務", "stroke":11, "grade":5, "jlpt":"N3"},
        {"kanji":"無", "stroke":12, "grade":4, "jlpt":"N3"},
        {"kanji":"夢", "stroke":13, "grade":5, "jlpt":"N3"},
        {"kanji":"霧", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"娘", "stroke":10, "grade":7, "jlpt":"N2"},
        {"kanji":"名", "stroke":6, "grade":1, "jlpt":"N5"},
        {"kanji":"命", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"明", "stroke":8, "grade":2, "jlpt":"N4"},
        {"kanji":"迷", "stroke":9, "grade":5, "jlpt":"N2"},
        {"kanji":"冥", "stroke":10, "grade":7, "jlpt":"-"},
        {"kanji":"盟", "stroke":13, "grade":6, "jlpt":"N1"},
        {"kanji":"銘", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"鳴", "stroke":14, "grade":2, "jlpt":"N3"},
        {"kanji":"滅", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"免", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"面", "stroke":9, "grade":3, "jlpt":"N3"},
        {"kanji":"綿", "stroke":14, "grade":5, "jlpt":"N2"},
        {"kanji":"麺", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"茂", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"模", "stroke":14, "grade":6, "jlpt":"N2"},
        {"kanji":"毛", "stroke":4, "grade":2, "jlpt":"N3"},
        {"kanji":"妄", "stroke":6, "grade":7, "jlpt":"N1"},
        {"kanji":"盲", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"耗", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"猛", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"網", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"目", "stroke":5, "grade":1, "jlpt":"N5"},
        {"kanji":"黙", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"門", "stroke":8, "grade":2, "jlpt":"N5"},
        {"kanji":"紋", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"問", "stroke":11, "grade":3, "jlpt":"N4"},
        {"kanji":"冶", "stroke":7, "grade":7, "jlpt":"-"},
        {"kanji":"夜", "stroke":8, "grade":2, "jlpt":"N4"},
        {"kanji":"野", "stroke":11, "grade":2, "jlpt":"N4"},
        {"kanji":"弥", "stroke":8, "grade":7, "jlpt":"-"},
        {"kanji":"厄", "stroke":4, "grade":7, "jlpt":"N1"},
        {"kanji":"役", "stroke":7, "grade":3, "jlpt":"N3"},
        {"kanji":"約", "stroke":9, "grade":4, "jlpt":"N3"},
        {"kanji":"訳", "stroke":11, "grade":6, "jlpt":"N2"},
        {"kanji":"薬", "stroke":16, "grade":3, "jlpt":"N4"},
        {"kanji":"躍", "stroke":21, "grade":7, "jlpt":"N1"},
        {"kanji":"闇", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"由", "stroke":5, "grade":3, "jlpt":"N3"},
        {"kanji":"油", "stroke":8, "grade":3, "jlpt":"N3"},
        {"kanji":"喩", "stroke":12, "grade":7, "jlpt":"-"},
        {"kanji":"愉", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"諭", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"輸", "stroke":16, "grade":5, "jlpt":"N3"},
        {"kanji":"癒", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"唯", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"友", "stroke":4, "grade":2, "jlpt":"N5"},
        {"kanji":"有", "stroke":6, "grade":3, "jlpt":"N4"},
        {"kanji":"勇", "stroke":9, "grade":4, "jlpt":"N2"},
        {"kanji":"幽", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"悠", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"郵", "stroke":11, "grade":6, "jlpt":"N3"},
        {"kanji":"湧", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"猶", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"裕", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"遊", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"雄", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"誘", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"憂", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"融", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"優", "stroke":17, "grade":6, "jlpt":"N3"},
        {"kanji":"与", "stroke":3, "grade":7, "jlpt":"N2"},
        {"kanji":"予", "stroke":4, "grade":3, "jlpt":"N3"},
        {"kanji":"余", "stroke":7, "grade":5, "jlpt":"N2"},
        {"kanji":"誉", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"預", "stroke":13, "grade":6, "jlpt":"N3"},
        {"kanji":"幼", "stroke":5, "grade":6, "jlpt":"N2"},
        {"kanji":"用", "stroke":5, "grade":2, "jlpt":"N4"},
        {"kanji":"羊", "stroke":6, "grade":3, "jlpt":"N2"},
        {"kanji":"妖", "stroke":7, "grade":7, "jlpt":"-"},
        {"kanji":"洋", "stroke":9, "grade":3, "jlpt":"N4"},
        {"kanji":"要", "stroke":9, "grade":4, "jlpt":"N3"},
        {"kanji":"容", "stroke":10, "grade":5, "jlpt":"N3"},
        {"kanji":"庸", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"揚", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"揺", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"葉", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"陽", "stroke":12, "grade":3, "jlpt":"N2"},
        {"kanji":"溶", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"腰", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"様", "stroke":14, "grade":3, "jlpt":"N3"},
        {"kanji":"瘍", "stroke":14, "grade":7, "jlpt":"-"},
        {"kanji":"踊", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"窯", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"養", "stroke":15, "grade":4, "jlpt":"N2"},
        {"kanji":"擁", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"謡", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"曜", "stroke":18, "grade":2, "jlpt":"N4"},
        {"kanji":"抑", "stroke":7, "grade":7, "jlpt":"N2"},
        {"kanji":"沃", "stroke":7, "grade":7, "jlpt":"-"},
        {"kanji":"浴", "stroke":10, "grade":4, "jlpt":"N2"},
        {"kanji":"欲", "stroke":11, "grade":6, "jlpt":"N3"},
        {"kanji":"翌", "stroke":11, "grade":6, "jlpt":"N2"},
        {"kanji":"翼", "stroke":17, "grade":7, "jlpt":"N1"},
        {"kanji":"拉", "stroke":8, "grade":7, "jlpt":"-"},
        {"kanji":"裸", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"羅", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"来", "stroke":7, "grade":2, "jlpt":"N5"},
        {"kanji":"雷", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"頼", "stroke":16, "grade":7, "jlpt":"N2"},
        {"kanji":"絡", "stroke":12, "grade":7, "jlpt":"N3"},
        {"kanji":"落", "stroke":12, "grade":3, "jlpt":"N3"},
        {"kanji":"酪", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"辣", "stroke":14, "grade":7, "jlpt":"-"},
        {"kanji":"乱", "stroke":7, "grade":6, "jlpt":"N2"},
        {"kanji":"卵", "stroke":7, "grade":6, "jlpt":"N3"},
        {"kanji":"覧", "stroke":17, "grade":6, "jlpt":"N2"},
        {"kanji":"濫", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"藍", "stroke":18, "grade":7, "jlpt":"-"},
        {"kanji":"欄", "stroke":20, "grade":7, "jlpt":"N1"},
        {"kanji":"吏", "stroke":6, "grade":7, "jlpt":"-"},
        {"kanji":"利", "stroke":7, "grade":4, "jlpt":"N4"},
        {"kanji":"里", "stroke":7, "grade":2, "jlpt":"N1"},
        {"kanji":"理", "stroke":11, "grade":2, "jlpt":"N4"},
        {"kanji":"痢", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"裏", "stroke":13, "grade":6, "jlpt":"N3"},
        {"kanji":"履", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"璃", "stroke":15, "grade":7, "jlpt":"-"},
        {"kanji":"離", "stroke":19, "grade":7, "jlpt":"N2"},
        {"kanji":"陸", "stroke":11, "grade":4, "jlpt":"N2"},
        {"kanji":"立", "stroke":5, "grade":1, "jlpt":"N5"},
        {"kanji":"律", "stroke":9, "grade":6, "jlpt":"N2"},
        {"kanji":"慄", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"略", "stroke":11, "grade":5, "jlpt":"N2"},
        {"kanji":"柳", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"流", "stroke":10, "grade":3, "jlpt":"N3"},
        {"kanji":"留", "stroke":10, "grade":5, "jlpt":"N3"},
        {"kanji":"竜", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"粒", "stroke":11, "grade":7, "jlpt":"N2"},
        {"kanji":"隆", "stroke":11, "grade":7, "jlpt":"-"},
        {"kanji":"硫", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"侶", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"旅", "stroke":10, "grade":3, "jlpt":"N4"},
        {"kanji":"虜", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"慮", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"了", "stroke":2, "grade":7, "jlpt":"N3"},
        {"kanji":"両", "stroke":6, "grade":3, "jlpt":"N3"},
        {"kanji":"良", "stroke":7, "grade":4, "jlpt":"N2"},
        {"kanji":"料", "stroke":10, "grade":4, "jlpt":"N4"},
        {"kanji":"涼", "stroke":11, "grade":7, "jlpt":"N3"},
        {"kanji":"猟", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"陵", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"量", "stroke":12, "grade":4, "jlpt":"N3"},
        {"kanji":"僚", "stroke":14, "grade":7, "jlpt":"N2"},
        {"kanji":"領", "stroke":14, "grade":5, "jlpt":"N2"},
        {"kanji":"寮", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"療", "stroke":17, "grade":7, "jlpt":"N2"},
        {"kanji":"瞭", "stroke":17, "grade":7, "jlpt":"-"},
        {"kanji":"糧", "stroke":18, "grade":7, "jlpt":"N1"},
        {"kanji":"力", "stroke":2, "grade":1, "jlpt":"N5"},
        {"kanji":"緑", "stroke":14, "grade":3, "jlpt":"N3"},
        {"kanji":"林", "stroke":8, "grade":1, "jlpt":"N4"},
        {"kanji":"厘", "stroke":9, "grade":7, "jlpt":"-"},
        {"kanji":"倫", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"輪", "stroke":15, "grade":4, "jlpt":"N2"},
        {"kanji":"隣", "stroke":16, "grade":7, "jlpt":"N2"},
        {"kanji":"臨", "stroke":18, "grade":6, "jlpt":"N1"},
        {"kanji":"瑠", "stroke":14, "grade":7, "jlpt":"-"},
        {"kanji":"涙", "stroke":10, "grade":7, "jlpt":"N3"},
        {"kanji":"累", "stroke":11, "grade":7, "jlpt":"N1"},
        {"kanji":"塁", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"類", "stroke":18, "grade":4, "jlpt":"N3"},
        {"kanji":"令", "stroke":5, "grade":4, "jlpt":"N2"},
        {"kanji":"礼", "stroke":5, "grade":3, "jlpt":"N3"},
        {"kanji":"冷", "stroke":7, "grade":4, "jlpt":"N3"},
        {"kanji":"励", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"戻", "stroke":7, "grade":7, "jlpt":"N3"},
        {"kanji":"例", "stroke":8, "grade":4, "jlpt":"N3"},
        {"kanji":"鈴", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"零", "stroke":13, "grade":7, "jlpt":"N2"},
        {"kanji":"霊", "stroke":15, "grade":7, "jlpt":"N1"},
        {"kanji":"隷", "stroke":16, "grade":7, "jlpt":"-"},
        {"kanji":"齢", "stroke":17, "grade":7, "jlpt":"N3"},
        {"kanji":"麗", "stroke":19, "grade":7, "jlpt":"N1"},
        {"kanji":"暦", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"歴", "stroke":14, "grade":5, "jlpt":"N3"},
        {"kanji":"列", "stroke":6, "grade":3, "jlpt":"N2"},
        {"kanji":"劣", "stroke":6, "grade":7, "jlpt":"N2"},
        {"kanji":"烈", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"裂", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"恋", "stroke":10, "grade":7, "jlpt":"N3"},
        {"kanji":"連", "stroke":10, "grade":4, "jlpt":"N3"},
        {"kanji":"廉", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"練", "stroke":14, "grade":3, "jlpt":"N3"},
        {"kanji":"錬", "stroke":16, "grade":7, "jlpt":"N1"},
        {"kanji":"呂", "stroke":7, "grade":7, "jlpt":"N1"},
        {"kanji":"炉", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"賂", "stroke":13, "grade":7, "jlpt":"-"},
        {"kanji":"路", "stroke":13, "grade":3, "jlpt":"N3"},
        {"kanji":"露", "stroke":21, "grade":7, "jlpt":"N1"},
        {"kanji":"老", "stroke":6, "grade":4, "jlpt":"N3"},
        {"kanji":"労", "stroke":7, "grade":4, "jlpt":"N3"},
        {"kanji":"弄", "stroke":7, "grade":7, "jlpt":"-"},
        {"kanji":"郎", "stroke":9, "grade":7, "jlpt":"N1"},
        {"kanji":"朗", "stroke":10, "grade":6, "jlpt":"N1"},
        {"kanji":"浪", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"廊", "stroke":12, "grade":7, "jlpt":"N1"},
        {"kanji":"楼", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"漏", "stroke":14, "grade":7, "jlpt":"N1"},
        {"kanji":"籠", "stroke":22, "grade":7, "jlpt":"-"},
        {"kanji":"六", "stroke":4, "grade":1, "jlpt":"N5"},
        {"kanji":"録", "stroke":16, "grade":4, "jlpt":"N3"},
        {"kanji":"麓", "stroke":19, "grade":7, "jlpt":"-"},
        {"kanji":"論", "stroke":15, "grade":6, "jlpt":"N3"},
        {"kanji":"和", "stroke":8, "grade":3, "jlpt":"N4"},
        {"kanji":"話", "stroke":13, "grade":2, "jlpt":"N5"},
        {"kanji":"賄", "stroke":13, "grade":7, "jlpt":"N1"},
        {"kanji":"脇", "stroke":10, "grade":7, "jlpt":"N1"},
        {"kanji":"惑", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"枠", "stroke":8, "grade":7, "jlpt":"N1"},
        {"kanji":"湾", "stroke":12, "grade":7, "jlpt":"N2"},
        {"kanji":"腕", "stroke":12, "grade":7, "jlpt":"N2"}
    ]
}