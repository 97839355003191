import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Textarea,
    useColorModeValue,
    VStack,
    Text
} from '@chakra-ui/react';
import React from 'react';
import { BsPerson } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import Head from '../functionalComponents/Head';
import Topbar from './Topbar';
import Footer from './Footer';

export default function Contact() {
    React.useEffect(()=>{
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, 200);
    }, [])

    return (
        <>
            <Head 
                url={window.location.href} 
                title={`Contact | LangLabJP`}
                description={`Contact | LangLabJP`}
            />
            <Topbar />
            <Flex
                bg={useColorModeValue('gray.100', 'gray.900')}
                align='center'
                justify='center'
                id='contact'
            >
                <Box
                    borderRadius='lg'
                    m={{ base: 0, md: 0, lg: 0 }}
                    p={{ base: 5, lg: 10 }}
                >
                    <Box>
                        <VStack spacing={{ base: 4, md: 8, lg: 8 }}>
                            <Heading
                                fontSize={{
                                    base: '4xl',
                                    md: '5xl',
                                }}
                            >
                                Contact Us
                            </Heading>

                            <Stack
                                spacing={{ base: 4, md: 8, lg: 20 }}
                                direction={{ base: 'column', md: 'row' }}
                            >
                                <Box
                                    bg={useColorModeValue('white', 'gray.700')}
                                    borderRadius='lg'
                                    p={8}
                                    color={useColorModeValue('gray.700', 'whiteAlpha.900')}
                                    shadow='base'
                                >
                                    <VStack spacing={5}>
                                        <FormControl isRequired>
                                            <FormLabel>Name</FormLabel>

                                            <InputGroup>
                                                <InputLeftElement children={<BsPerson />} />
                                                <Input type='text' name='name' placeholder='Your Name' />
                                            </InputGroup>
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel>Email</FormLabel>

                                            <InputGroup>
                                                <InputLeftElement children={<MdOutlineEmail />} />
                                                <Input
                                                    type='email'
                                                    name='email'
                                                    placeholder='Your Email'
                                                />
                                            </InputGroup>
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel>Message</FormLabel>

                                            <Textarea
                                                name='message'
                                                placeholder='Your Message'
                                                rows={6}
                                                resize='none'
                                            />
                                        </FormControl>

                                        <Button
                                            colorScheme='blue'
                                            bg='blue.400'
                                            color='white'
                                            _hover={{
                                                bg: 'blue.500',
                                            }}
                                            disabled
                                        >
                                            Send Message
                                        </Button>
                                        <Text color={'red.600'}>This form is not in use. Under construction. Contact us via email (tim@lamblabo.com) with your message.</Text>
                                    </VStack>
                                </Box>
                            </Stack>
                        </VStack>
                    </Box>
                </Box>
            </Flex>
            <Footer />
        </>
    );
}