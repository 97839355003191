import { 
    useState, 
    useEffect 
} from 'react';
import {
    VStack,
    Box,
    Heading,
    Divider,
    Center,
    Spinner,
    Wrap,
    WrapItem,
    Tag,
    Text
} from '@chakra-ui/react';
import axios from 'axios';
import { Link } from '../functionalComponents/ChakraLinkAsReactRouter';

export default function JedictSentenceBreakdown(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [sentenceBreakdown, setSentenceBreakdown] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/api/sentenceBreakdown/${props.query}`)
            .then(res => {
                if(res.data.status === 'OK'){
                    setSentenceBreakdown(res.data.sentence_breakdown);
                    setIsLoading(false);
                }
            });
    }, [props.query]);

    return (
        <VStack align='stretch' spacing='10px' mb={8}>
            <Box>
                <Heading as='h1' size='sm' noOfLines={1}>
                    Sentence Breakdown
                </Heading>
                <Divider />
            </Box>
            <Box>
                {isLoading ? (
                    <>
                        <Center>
                            <Spinner
                                thickness='1.5px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='orange.500'
                                size='sm'
                            />
                        </Center>
                    </>
                ) : (
                    <>
                        <Wrap spacing={1} display={sentenceBreakdown.length ? '' : 'none'}>
                            {sentenceBreakdown.map((word, index) => (
                                <WrapItem key={index}>
                                    <Tag key={index} colorScheme='orange'>
                                        <Link to={`../${word}`}>
                                            {word}
                                        </Link>
                                    </Tag>
                                </WrapItem>
                            ))}
                        </Wrap>
                        <Text size='sm' display={sentenceBreakdown.length ? 'none' : ''}>
                            Cannot be broken down anymore!
                        </Text>
                    </>
                )}
            </Box>
        </VStack>
    );
}
