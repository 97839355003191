import { 
    useState, 
    useEffect 
} from 'react';
import { 
    VStack, 
    Center,
    Spinner,
    Box, 
    Heading, 
    Text,
    Highlight
} from '@chakra-ui/react';
import axios from 'axios';

function WordCard({ lemma, sublemma, translation, description }) {
    return (
        <Box p={5} mt={3} w='100%' shadow='md' borderWidth='1px'>
            <Heading as='h1' fontSize='2xl' pl={1}>{lemma}</Heading>
            <Heading as='h1' fontSize='md' mt={1}>{sublemma}</Heading>
            <Box mt={4}>
                <Text as='span' fontSize='sm'>1.　</Text>
                <Text as='b' fontSize='md'>{translation}</Text>
                <Text as='span' fontSize='sm' display={description ? '' : 'none'}> ― {description}</Text>
            </Box>
        </Box>
    );
}

export default function JedictKnowledge(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [words, setWords] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/api/knowledge/search/en/${props.query}`)
            .then(res => {
                if(res.data.status === 'OK'){
                    setWords(res.data.wiki_results);
                    // console.log(res.data.wiki_results);
                    setIsLoading(false);
                }
            });
    }, [props.query]);

    return (
        <VStack spacing={4}>
            {isLoading ? (
                <>
                    <Center>
                        <Spinner
                            thickness='1.5px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='sm'
                        />
                    </Center>
                </>
            ) : (
                <>
                    <Box w='100%' display={words.length ? '' : 'none'}>
                        {words.map((word, index) => (
                            <WordCard
                                key={index}
                                lemma={word.lemma}
                                sublemma={word.sublemma ? `（${word.sublemma}）` : ''}
                                translation={word.translation}
                                description={word.description ? word.description : ''}
                            />
                        ))}
                    </Box>
                    <Box w='100%' display={words.length ? 'none' : ''}>
                        <Highlight query={['Words', 'Sentence Breakdown', 'Related Searches']} styles={{ px: '1', py: '1', bg: 'orange.100' }}>
                            No matches found! Take a look at Words / Sentence Breakdown / Related Searches. Or you can try different keyword.
                        </Highlight>
                    </Box>
                </>
            )}
        </VStack>
    );
}