import { useParams, Navigate } from 'react-router-dom';
import {
    useState, 
    useEffect
} from 'react';
import { 
    VStack, 
    Center,
    Box, 
    Heading, 
    Divider,
    TableContainer, 
    Table, 
    Tr, 
    Th, 
    Tbody, 
    Td,
    Button,
    Spinner,
    Text,
} from '@chakra-ui/react';
import Head from '../functionalComponents/Head';
import axios from 'axios';
import Topbar from './Topbar';
import Footer from './Footer';
import KdictSearchbox from './KdictSearchbox';
import JedictWordsForKanji from './JedictWordsForKanji';
import KanjiAcknowledgement from './KanjiAcknowledgement';
import { BsPlay } from 'react-icons/bs';

function KanjiCard({ kanji, kunReadings, onReadings, meanings, strokes, svg }) {
    const [isSvgPlaying, setIsSvgPlaying] = useState(false);

    return (
        <Box mt={3} w='100%'>
            <Heading as='h1' fontSize='5xl' p={2} mb={2} borderWidth='1px' borderRadius='15px' borderColor='blue.50' backgroundColor='blue.50' color='blue.700'>
                <Center>{kanji}</Center>
            </Heading>
            <TableContainer w='100%' fontSize='lg'>
                <Table variant='simple' colorScheme='gray' color={'gray.600'} align='left'>
                    <Tbody>
                        <Tr>
                            <Th w='150px'>訓読み<br/>Kun-readings</Th>
                            <Td dangerouslySetInnerHTML={{__html: kunReadings.length ? kunReadings.join('<br/>') : '-'}}></Td>
                        </Tr>
                        <Tr>
                            <Th w='150px'>音読み<br/>On-readings</Th>
                            <Td dangerouslySetInnerHTML={{__html: onReadings.length ? onReadings.join('<br/>') : '-'}}></Td>
                        </Tr>
                        <Tr>
                            <Th w='150px'>意味<br/>Meanings</Th>
                            <Td dangerouslySetInnerHTML={{__html: meanings.length ? meanings.join(',<br/>') : '-'}}></Td>
                        </Tr>
                        <Tr>
                            <Th w='150px'>画数<br/>Number of strokes</Th>
                            <Td>{strokes}</Td>
                        </Tr>
                        {svg !== '' ?
                            <Tr>
                                <Th w='150px'>筆順<br/>Stroke order</Th>
                                <Td>
                                    <Box 
                                        id='kanjiSvg' 
                                        dangerouslySetInnerHTML={{__html: svg}} 
                                    /> 
                                    <Button 
                                        colorScheme='blue' 
                                        onClick={() => {
                                            let total_time = window.kanjiSVGAnimation(500);
                                            setIsSvgPlaying(true);
                                            setTimeout(() => {
                                                setIsSvgPlaying(false);
                                            }, total_time);
                                        }}
                                        disabled={isSvgPlaying}
                                    >
                                        <BsPlay />
                                        Animation
                                    </Button>
                                </Td>
                            </Tr>
                            : ''
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default function Kanji() {
    const params = useParams();
    const [isKeywordValid, SetIsKeywordValid] = useState(true);
    const [kanjis, setKanjis] = useState([]);
    const [kanjiSvg, setKanjiSvg] = useState('');
    
    useEffect(() => {
        if(params.kanji.length > 1) SetIsKeywordValid(false);
        setKanjiSvg('');
        window.scrollTo({ top: 0, behavior: 'smooth' });

        axios.get(`${process.env.REACT_APP_API_URL}/api/getKanjiInfo/${params.kanji}`)
            .then(res => {
                if(res.data.status === 'OK'){
                    if(res.data.kanjis_info.length !== 1 || res.data.kanjis_info[0].kanji !== params.kanji) SetIsKeywordValid(false);
                    setKanjis(res.data.kanjis_info);

                    axios.get(`${process.env.REACT_APP_API_URL}/api/getKanjiSvg/${res.data.kanjis_info[0].unicode}`)
                        .then(res2 => {
                            if(res2.data.status === 'OK'){
                                setKanjiSvg(res2.data.svg);
                            }
                        })
                }
            });
    }, [params.kanji]);

    return (
        <>
            {!isKeywordValid && <Navigate to={`/Kdict/${params.kanji}`} replace={false} />}
            <Head 
                url={window.location.href} 
                title={`Japanese Kanji 「${params.kanji}」 | LangLabJP`}
                description={`Japanese Kanji 「${params.kanji}」 | LangLabJP`}
            />
            <Topbar />
            <VStack m='5px' spacing='24px'>
                <Box w='1100px' maxW='100%'>
                    <KdictSearchbox query={params.kanji} />
                </Box>
                <Box w='900px' maxW='100%'>
                    {kanjis.length ?
                        <>
                            <KanjiCard
                                kanji={kanjis[0].kanji}
                                kunReadings={kanjis[0].kun_readings}
                                onReadings={kanjis[0].on_readings}
                                meanings={kanjis[0].meanings}
                                strokes={kanjis[0].stroke_count}
                                svg = {kanjiSvg}
                            />
                            <Box mt={12}>
                                <Heading as='h1' size='md' noOfLines={1}>
                                    「{kanjis[0].kanji}」 in different fonts
                                </Heading>
                                <Divider />
                                <Text as='span' fontSize='5xl' p={4} fontFamily='BIZ UDMincho'>{kanjis[0].kanji}</Text>
                                <Text as='span' fontSize='5xl' p={4} fontFamily='New Tegomin'>{kanjis[0].kanji}</Text>
                                <Text as='span' fontSize='5xl' p={4} fontFamily='Yuji Mai'>{kanjis[0].kanji}</Text>
                                <Text as='span' fontSize='5xl' p={4} fontFamily='Zen Kurenaido'>{kanjis[0].kanji}</Text>
                            </Box>
                            <Box mt={12}>
                                <Heading as='h1' size='md' noOfLines={1}>
                                    Words containing 「{kanjis[0].kanji}」
                                </Heading>
                                <Divider />
                                <JedictWordsForKanji query={kanjis[0].kanji} />
                            </Box>
                        </>
                        :
                        <Center>
                            <Spinner
                                thickness='1.5px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='sm'
                            />
                        </Center>
                    }
                </Box>
            </VStack>
            <KanjiAcknowledgement />
            <Footer />
        </>
    );
}

  