import { useParams } from 'react-router-dom';
import { 
    Stack, 
    VStack, 
    Box 
} from '@chakra-ui/react';
import Head from '../functionalComponents/Head';
import KdictSearchbox from './KdictSearchbox';
import KdictResultsMain from './KdictResultsMain';
import JedictOtherDicts from './JedictOtherDicts';
import KanjiAcknowledgement from './KanjiAcknowledgement';
import { useEffect } from 'react';

export default function KdictResults(){
    const params = useParams();

    useEffect(()=>{
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, 200);
    }, [params])

    return (
        <>
            <Head 
                url={window.location.href} 
                title={`${params.searchQuery} | Kanji Dictionary (漢字辞書) by LangLabJP`}
                description={`${params.searchQuery} | Kanji Dictionary (漢字辞書) by LangLabJP`}
            />
            <VStack mt='5px' spacing='24px'>
                <Box w='1100px' maxW='100%' px={2}>
                    <KdictSearchbox query={params.searchQuery} />
                </Box>
                <Box w='1100px' maxW='100%' px={2}>
                    <Stack direction={['column', 'row']} spacing='24px' maxW='100%'>
                        <Box w='700%' maxW='100%' minH='400px'>
                            <KdictResultsMain query={params.searchQuery}/>
                        </Box>
                        <Box w='300%' maxW='100%'>
                            <JedictOtherDicts query={params.searchQuery}/>
                        </Box>
                    </Stack>
                </Box>
                <Box w='100%'>
                    <KanjiAcknowledgement />
                </Box>
            </VStack>
        </>
    )
}