import { useEffect } from 'react';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-3W3YB4RX8G');

const GoogleAnalytics = () => {
    useEffect(() => {
        ReactGA.send('pageview');
    }, []);
};

export default GoogleAnalytics;