import { Outlet } from 'react-router-dom';
import Topbar from './Topbar';
import Footer from './Footer';

export default function Kdict(){
    return (
        <>
            <Topbar />
            <Outlet />
            <Footer />
        </>
    )
}