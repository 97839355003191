import {
    Box,
    Center,
    Container,
    Stack,
    Heading,
    Text,
    useColorModeValue,
    Link
} from '@chakra-ui/react';

export default function JedictAcknowledgement() {
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
            w='100%'
            mt={50}
        >
            <Center>
                <Heading as='h2' size='sm' pt={4} pl={4}>Acknowledgement</Heading>
            </Center>
            <Container
                as={Stack}
                maxW={'6xl'}
                py={2}
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                justify={{ base: 'center', md: 'space-between' }}
                align={{ base: 'start', md: 'start' }}
            >
                <Box>
                    <Text as='p'>
                        The "Words" part of LangLabJP Japanese-English Dictionary uses the <Link href='http://www.edrdg.org/wiki/index.php/JMdict-EDICT_Dictionary_Project'>JMdict</Link> files. These files are the property of the <Link href='http://www.edrdg.org/'>Electronic Dictionary Research and Development Group</Link>, and are used in conformance with the Group's <Link href='http://www.edrdg.org/edrdg/licence.html'>licence</Link>.
                    </Text>
                </Box>
                <Box>
                    <Text as='p'>
                        The "Knowledge" part of LangLabJP Japanese-English Dictionary uses the data comes from <Link href='https://www.wikidata.org/'>Wikidata</Link>, under the <Link href='https://creativecommons.org/publicdomain/zero/1.0/'>Creative Commons CC0 License</Link>.
                    </Text>
                </Box>
            </Container>
        </Box>
    );
}