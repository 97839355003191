import {
    Tabs,
    TabList,
    Divider,
    TabPanels,
    Tab,
    TabPanel
} from '@chakra-ui/react';
import JedictWords from './JedictWords';
import JedictKnowledge from './JedictKnowledge';
  
export default function JedictResultsMain(props) {
    return (
        <Tabs variant='soft-rounded' colorScheme='blue' isFitted isLazy>
            <TabList>
                <Tab>Words</Tab>
                <Tab>Knowledge</Tab>
            </TabList>
            <Divider />
            <TabPanels>
                <TabPanel>
                    <JedictWords query={props.query}/>
                </TabPanel>
                <TabPanel>
                    <JedictKnowledge query={props.query}/>
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
}
  