import { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import { Heading, InputGroup, Input, InputRightElement } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { SymbolHankaku2Zenkaku } from '../functionalComponents/Helper';

export default function KdictSearchbox(prop) {
    const [searchQuery, setSearchQuery] = useState(prop.query);
    const [searchButtonOnclick, setSearchButtonOnclick] = useState(false);
    const [isTyping, setIsTyping] = useState(false)

    useEffect(()=>{
        setSearchQuery(prop.query);
        setSearchButtonOnclick(false);
    }, [prop.query])

    function search(query) {
        query = query.replace(/[ 　]+$/g, '');
        setSearchQuery(query);
        if (query !== prop.query) {
            setSearchButtonOnclick(true);
        }else{
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    return (
        <>
            <Heading as='h1' size={'md'} color={'blue.700'} pl={1} my={2} noOfLines={1}>
                Kanji Dictionary 漢字辞書
            </Heading>
            <InputGroup
                w='100%'
                borderWidth='1.5px'
                borderColor='blue.200'
                borderRadius='10px'
            >
                <Input
                    placeholder='Japanese kanji / reading (phonetic in kana) / sentence contains kanji(s)'
                    border='none'
                    size='lg'
                    onChange={(e) => setSearchQuery(SymbolHankaku2Zenkaku(e.target.value).replace(/[ 　\n\t]/g, ''))}
                    value={decodeURI(searchQuery)}
                    onCompositionStart={() => {
                        setIsTyping(true);
                        //console.log('日本語IME入力中...');
                    }}
                    onCompositionEnd={() => {
                        setTimeout(()=>{
                            setIsTyping(false);
                        }, 500)
                        //console.log('日本語IME変換完了...');
                    }}
                    onKeyUp={e => e.key === 'Enter' && !isTyping && search(searchQuery)}
                />
                <InputRightElement
                    children={
                        <SearchIcon
                            cursor='pointer'
                            color='blue.400'
                            onClick={() => search(searchQuery)}
                        />
                    }
                />
                {searchButtonOnclick && <Navigate to={`/Kdict/${searchQuery}`} replace={false} />}
            </InputGroup>
        </>
    );
}
