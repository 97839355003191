// import { Navigate } from "react-router-dom"
import { useState, useEffect } from 'react';
import Head from '../functionalComponents/Head';
import Topbar from "./Topbar"
import HomeCarousels from "./HomeCarousels"
import HomeAcknowledgement from "./HomeAcknowledgement"
import Footer from "./Footer"

import {
    Icon,
    Center,
    SimpleGrid,
    Stack,
    LinkBox,
    LinkOverlay,
    Box,
    Heading,
    Text,
    Flex,
} from '@chakra-ui/react';
import { GiBookCover, GiSpellBook, GiArtificialIntelligence, 
    GiPerspectiveDiceOne, GiPerspectiveDiceTwo, GiPerspectiveDiceThree, GiPerspectiveDiceFour, GiPerspectiveDiceFive, GiPerspectiveDiceSix,
    GiInvertedDice1, GiInvertedDice2, GiInvertedDice3, GiInvertedDice4, GiInvertedDice5 
} from "react-icons/gi";
import { RiFileList3Fill } from 'react-icons/ri';
import { Link } from '../functionalComponents/ChakraLinkAsReactRouter';


function HomeCard({icon, heading, text, link}){
    const colorList = ['gray', 'red', 'orange', 'yellow', 'green', 'teal', 'blue', 'cyan', 'purple', 'pink'];
    const [randomNumber, setRandomNumber] = useState(Math.floor(Math.random() * 10));
    const [borderWidth, setBorderWidth] = useState('3px');

    useEffect(()=>{
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, 200);
    }, [])

    return (
        <LinkBox w='100%' p={1} borderLeftWidth={borderWidth} borderColor={colorList[randomNumber]+'.200'} color={colorList[randomNumber]+'.600'}
            onMouseOver={
                () => {
                    setBorderWidth('5px');
                    setRandomNumber(Math.floor(Math.random() * 10));
                }
            } 
            onMouseLeave={
                () => {
                    setBorderWidth('3px');
                    setRandomNumber(Math.floor(Math.random() * 10));
                }
            }
        >
            <Stack direction='row' spacing='15px'>
                <Flex
                    align='center'
                    justify='center'
                    rounded='full'
                    pl={2}
                >
                    <Icon as={icon} w={10} h={10} />
                </Flex>
                <Box>
                    <Heading size='md' mb='1'>
                        <LinkOverlay to={link} as={Link} _hover={{textDecoration: 'none'}}>
                            {heading}
                        </LinkOverlay>
                    </Heading>
                    <Text>
                        {text}
                    </Text>
                </Box>
            </Stack>
        </LinkBox>
    )
}

export default function Home(){
    return (
        <>
            <Head 
                url={window.location.href} 
                title={`Home | LangLabJP`}
                description={`Home | LangLabJP`}
            />
            <Topbar />
            <HomeCarousels />

            {/* <Center>
                <Heading as='h1' mt={8} fontFamily='Courgette' className='rainbow_font'>Dictionaries</Heading>
            </Center> */}
            <Center mt={7}>
                <SimpleGrid columns={{sm: 1, md: 2, lg: 3}} spacing='20px' w='1100px' maxW='100%' m={7}>
                    <HomeCard icon={GiBookCover} heading='Japanese-English Dictionary' text='和英辞書' link='/Jedict' />
                    <HomeCard icon={GiSpellBook} heading='Kanji Dictionary' text='漢字辞書' link='/Kdict' />
                </SimpleGrid>
            </Center>

            <Center>
                <SimpleGrid columns={{sm: 1, md: 2, lg: 3}} spacing='20px' w='1100px' maxW='100%' m={7}>
                    <HomeCard icon={GiInvertedDice1} heading='JLPT N1 Kanji List' text='日本語能力試験N1漢字リスト' link='/List/jlptkanji/n1' />
                    <HomeCard icon={GiInvertedDice2} heading='JLPT N2 Kanji List' text='日本語能力試験N2漢字リスト' link='/List/jlptkanji/n2' />
                    <HomeCard icon={GiInvertedDice3} heading='JLPT N3 Kanji List' text='日本語能力試験N3漢字リスト' link='/List/jlptkanji/n3' />
                    <HomeCard icon={GiInvertedDice4} heading='JLPT N4 Kanji List' text='日本語能力試験N4漢字リスト' link='/List/jlptkanji/n4' />
                    <HomeCard icon={GiInvertedDice5} heading='JLPT N5 Kanji List' text='日本語能力試験N5漢字リスト' link='/List/jlptkanji/n5' />
                </SimpleGrid>
            </Center>  

            <Center>
                <SimpleGrid columns={{sm: 1, md: 2, lg: 3}} spacing='20px' w='1100px' maxW='100%' m={7}>
                    <HomeCard icon={GiArtificialIntelligence} heading='Furigana-kun (Auto furigana)' text='ふりがな君 (自動ルビ振り)' link='/Furiganakun' />
                </SimpleGrid>
            </Center>

            <Center>
                <SimpleGrid columns={{sm: 1, md: 2, lg: 3}} spacing='20px' w='1100px' maxW='100%' m={7}>
                    <HomeCard icon={RiFileList3Fill} heading='Official List of Kanji in Common Use' text='常用漢字表' link='/List/joyokanji' />
                    <HomeCard icon={GiPerspectiveDiceOne} heading='Kanji taught in Grade 1' text='小学1年生で習う漢字' link='/List/gakunenbetsukanji/1' />
                    <HomeCard icon={GiPerspectiveDiceTwo} heading='Kanji taught in Grade 2' text='小学2年生で習う漢字' link='/List/gakunenbetsukanji/2' />
                    <HomeCard icon={GiPerspectiveDiceThree} heading='Kanji taught in Grade 3' text='小学3年生で習う漢字' link='/List/gakunenbetsukanji/3' />
                    <HomeCard icon={GiPerspectiveDiceFour} heading='Kanji taught in Grade 4' text='小学4年生で習う漢字' link='/List/gakunenbetsukanji/4' />
                    <HomeCard icon={GiPerspectiveDiceFive} heading='Kanji taught in Grade 5' text='小学5年生で習う漢字' link='/List/gakunenbetsukanji/5' />
                    <HomeCard icon={GiPerspectiveDiceSix} heading='Kanji taught in Grade 6' text='小学6年生で習う漢字' link='/List/gakunenbetsukanji/6' />
                </SimpleGrid>
            </Center>   

            <HomeAcknowledgement />
            <Footer />
        </>
        // <Navigate to={`/Jedict`} replace={true} />
    )
}