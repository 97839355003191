import { Box, Heading, Text, Button, Link } from '@chakra-ui/react';
import Head from '../functionalComponents/Head';
import Topbar from './Topbar';

export default function Page404() {
    return (
        <>
            <Head 
                url={window.location.href} 
                title={`Page Not Found | LangLabJP`}
                description={`Page Not Found | LangLabJP`}
            />
            <Topbar />
            <Box textAlign="center" py={20} px={6}>
                <Heading
                    display="inline-block"
                    as="h2"
                    size="2xl"
                    bgGradient="linear(to-r, blue.300, blue.100)"
                    backgroundClip="text"
                >
                    404
                </Heading>
                <Text fontSize="18px" mt={3} mb={2}>
                    Page Not Found
                </Text>
                <Text color={'gray.500'} mb={6}>
                    The page you're looking for does not seem to exist.
                </Text>

                <Button
                    colorScheme="blue"
                    bgGradient="linear(to-r, blue.300, blue.200, blue.100)"
                    color="white"
                    variant="solid"
                    as={Link}
                    href='/'
                >
                    Go to Home
                </Button>
            </Box>
        </>
    );
}