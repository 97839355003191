import { 
    useState, 
    useEffect 
} from 'react';
import { 
    VStack, 
    Center,
    Spinner,
    Box, 
    Heading, 
    Text,
    Divider,
    TableContainer, 
    Table, 
    Tr, 
    Th, 
    Tbody, 
    Td,
    Button,
    Flex,
    Spacer,
} from '@chakra-ui/react';
import axios from 'axios';
import { Link } from '../functionalComponents/ChakraLinkAsReactRouter';

function KanjiCard({ kanji, kunReadings, onReadings, meanings, display }) {
    return (
        <Box p={5} mt={3} w='100%' shadow='md' borderWidth='1px' display={display}>
            <Heading as='h1' fontSize='5xl' p={2} mb={2} borderWidth='1px' borderRadius='15px' borderColor='blue.50' backgroundColor='blue.50' color='blue.700'>
                <Center>{kanji}</Center>
            </Heading>
            <TableContainer w='100%' fontSize='lg'>
                <Table variant='simple' colorScheme='gray' color={'gray.600'} align='left'>
                    <Tbody>
                        <Tr>
                            <Th w='150px'>訓読み<br/>Kun-readings</Th>
                            <Td dangerouslySetInnerHTML={{__html: kunReadings.length ? kunReadings.join('<br/>') : '-'}}></Td>
                        </Tr>
                        <Tr>
                            <Th w='150px'>音読み<br/>On-readings</Th>
                            <Td dangerouslySetInnerHTML={{__html: onReadings.length ? onReadings.join('<br/>') : '-'}}></Td>
                        </Tr>
                        <Tr>
                            <Th w='150px'>意味<br/>Meanings</Th>
                            <Td dangerouslySetInnerHTML={{__html: meanings.length ? meanings.join(',<br/>') : '-'}}></Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Flex mt={2}>
                <Spacer/>
                <Link to={`/Kanji/${kanji}`} key={kanji} color={'blue.600'}>
                    Details →
                </Link>
            </Flex>
            
        </Box>
    );
}

export default function KdictResultsMain(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [kanjis, setKanjis] = useState([]);
    const [showMax, setShowMax]= useState(5);

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/api/getKanjiInfo/${props.query}`)
            .then(res => {
                if(res.data.status === 'OK'){
                    setKanjis(res.data.kanjis_info);
                    // console.log(res.data.kanjis_info);
                    setIsLoading(false);
                }
            });
        setShowMax(5);
    }, [props.query]);

    return (
        <>
            <Box>
                <Heading as='h1' size='md' pl={2} noOfLines={1}>
                    Kanji
                    {kanjis.length ? <Text as='span' fontSize='sm' color='blue.600'> − {kanjis.length} found</Text> : ''}
                </Heading>
                <Divider />
            </Box>
            <VStack spacing={4}>
                {isLoading ? (
                    <>
                        <Center>
                            <Spinner
                                thickness='1.5px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='sm'
                            />
                        </Center>
                    </>
                ) : (
                    <>
                        <Box w='100%' display={kanjis.length ? '' : 'none'}>
                            {kanjis.map((kanji, index) => (
                                <KanjiCard
                                    key={index}
                                    kanji={kanji.kanji}
                                    kunReadings={kanji.kun_readings}
                                    onReadings={kanji.on_readings}
                                    meanings={kanji.meanings}
                                    display={index < showMax ? 'block' : 'none'}
                                />
                            ))}
                            <Center>
                                <Button 
                                    my={2} 
                                    colorScheme='blue' 
                                    display={showMax < kanjis.length ? '' : 'none'} 
                                    onClick={() => setShowMax(showMax+5)}
                                >
                                    See more
                                </Button>
                            </Center>
                        </Box>
                        <Box w='100%' pl={2} display={kanjis.length ? 'none' : ''}>
                            No matches found!
                        </Box>
                    </>
                )}
            </VStack>
        </>
    );
}
  