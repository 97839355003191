import { useEffect } from 'react';
import { Stack, 
    VStack, 
    Box, 
    //Heading, 
    Text,
    List,
    ListItem,
    ListIcon
} from '@chakra-ui/react';
import Head from '../functionalComponents/Head';
import KdictSearchbox from './KdictSearchbox';
import { CheckIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Link } from '../functionalComponents/ChakraLinkAsReactRouter';


export default function KdictIndex(){
    useEffect(()=>{
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, 200);
    }, [])

    return (
        <>
            <Head 
                url={window.location.href} 
                title='Kanji Dictionary (漢字辞書) by LangLabJP' 
                description='Kanji Dictionary (漢字辞書) by LangLabJP' 
            />
            <VStack m='5px' spacing='24px'>
                <Box w='1100px' maxW='100%'>
                    <KdictSearchbox query='' />
                </Box>
                <Box w='1100px' maxW='100%'>
                    <Stack direction={['column', 'row']} spacing='12px' maxW='100%'>
                        <Box w='125%' maxW='100%'></Box>
                        <Box w='750%' maxW='100%'>
                            <Text>
                                <Text as='span' color={'blue.400'}>LangLab</Text><Text as='span' color={'red.400'}>JP</Text> Dictionaries are more than just a dictionary. 
                                You can use this Kanji dictionary (漢字辞書) to look up a Japanese kanji. 
                                Meaning, basic information, stroke order animation, frequently used words containing that kanji will be shown. 
                            </Text>
                            <Text my={6}>Here are some lists of Japanese kanji.</Text>
                            <List spacing={4} mb={8}>
                                <ListItem>
                                    <ListIcon as={CheckIcon} />
                                    <Link to={`/List/joyokanji`} color={'blue.600'}>
                                        常用漢字表 Joyo Kanji-hyo (Official List of Kanji in Common Use) 
                                    </Link>
                                </ListItem>

                                <ListItem>
                                    <ListIcon as={CheckIcon} />
                                    日本語能力試験 漢字リスト JLPT Kanji List
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={ChevronRightIcon} />
                                    <Link to={`/List/jlptkanji/n1`} color={'blue.600'} pr={2}>
                                        N1
                                    </Link>
                                    <Link to={`/List/jlptkanji/n2`} color={'blue.600'} pr={2}>
                                        N2
                                    </Link>
                                    <Link to={`/List/jlptkanji/n3`} color={'blue.600'} pr={2}>
                                        N3
                                    </Link>
                                    <Link to={`/List/jlptkanji/n4`} color={'blue.600'} pr={2}>
                                        N4
                                    </Link>
                                    <Link to={`/List/jlptkanji/n5`} color={'blue.600'} pr={2}>
                                        N5
                                    </Link>
                                </ListItem>

                                <ListItem>
                                    <ListIcon as={CheckIcon} />
                                    学年別漢字配当表 Gakunenbetsu Kanji Haitohyo (List of Kanji by School Year)
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={ChevronRightIcon} />
                                    <Link to={`/List/gakunenbetsukanji/1`} color={'blue.600'}>
                                        第1学年 Grade 1
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={ChevronRightIcon} />
                                    <Link to={`/List/gakunenbetsukanji/2`} color={'blue.600'}>
                                        第2学年 Grade 2
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={ChevronRightIcon} />
                                    <Link to={`/List/gakunenbetsukanji/3`} color={'blue.600'}>
                                        第3学年 Grade 3
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={ChevronRightIcon} />
                                    <Link to={`/List/gakunenbetsukanji/4`} color={'blue.600'}>
                                        第4学年 Grade 4
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={ChevronRightIcon} />
                                    <Link to={`/List/gakunenbetsukanji/5`} color={'blue.600'}>
                                        第5学年 Grade 5
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={ChevronRightIcon} />
                                    <Link to={`/List/gakunenbetsukanji/6`} color={'blue.600'}>
                                        第6学年 Grade 6
                                    </Link>
                                </ListItem>
                            </List>
                        </Box>
                        <Box w='125%' maxW='100%'></Box>
                    </Stack>
                </Box>
            </VStack>
        </>
    )
}