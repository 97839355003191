import { useEffect } from 'react';
import { 
    Stack, 
    Box, 
    Text,
    Heading,
} from '@chakra-ui/react';
import Head from '../functionalComponents/Head';
import Topbar from './Topbar';
import Footer from './Footer';


export default function PrivacyPolicy(){
    useEffect(()=>{
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, 200);
    }, [])

    return (
        <>
            <Head 
                url={window.location.href} 
                title='Privacy Policy | LangLabJP' 
                description='Privacy Policy | LangLabJP' 
            />
            <Topbar />

            <Stack direction={{base: 'column', lg:'row'}}>
                <Box w='800px' maxW='100%' p={3} mb={5}>
                    <Heading fontSize='lg'>LangLabJP プライバシーポリシー</Heading>

                    <Text mt={5} as={'div'}>
                        当サイトのプライバシーポリシー・免責事項を次の通り記載します。
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>個人情報の利用目的について</Heading>
                        当サイトでは、お問い合わせやコメント投稿の際に氏名・メールアドレス等の個人情報を入力いただく場合があります。<br/>
                        取得した個人情報は、必要な連絡のみに利用させていただくもので、これらの目的以外では利用いたしません。
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>個人情報の第三者開示について</Heading>
                        取得した個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。<br/>
                        ・本人の同意が得られた場合<br/>
                        ・法令により開示が求められた場合
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>Cookieの使用について</Heading>
                        当サイトでは、広告配信やアクセス解析のためにCookieを使用しています。<br/>
                        Cookieによりブラウザを識別していますが、特定の個人の識別はできない状態で匿名性が保たれています。<br/>
                        Cookieの使用を望まない場合、ブラウザからCookieを無効に設定できます。
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>広告配信サービスについて</Heading>
                        当サイトでは、第三者配信の広告サービスを利用して広告を掲載しています。<br/>
                        第三者配信事業者は、ユーザーの興味に応じたパーソナライズ広告を表示するためにCookieを使用しています。<br/>
                        広告設定については、直接広告配信事業者までお問い合わせください。
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>アクセス解析ツールについて</Heading>
                        当サイトでは、Googleアナリティクスによりアクセス情報を解析しています。<br/>
                        アクセス情報の解析にはCookieを使用しています。また、アクセス情報の収集はCookieを無効にすることで拒否できます。<br/>
                        Google社のデータ収集・処理の仕組みについては、下記のURLからご参照ください。<br/>
                        https://policies.google.com/technologies/partner-sites?hl=ja
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>免責事項</Heading>
                        当サイトは、掲載内容によって生じた損害に対する一切の責任を負いません。<br/>
                        各コンテンツでは、できる限り正確な情報提供を心がけておりますが、正確性や安全性を保証するものではありません。<br/>
                        また、リンク先の他サイトで提供される情報・サービスについても、責任を負いかねますのでご了承ください。
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>著作権</Heading>
                        当サイトに掲載されている内容の著作権は、二次的著作物あるいは編集著作物として運営者に帰属していますが、ご利用にあたり特に制限は設けておらず、自由に利用できます。<br/>
                        （一部除く。その場合は各ページの下部にある「Acknowledgement」をご確認ください。）<br/>
                        ただし、スクレイピングで大量のデータを連続して取得するなど、サーバに負荷をかける行為を断固禁止します。
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>プライバシーポリシーの変更</Heading>
                        当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本プライバシーポリシーの内容を適宜見直して改善に努めます。<br/>
                        修正された最新のプライバシーポリシーは常に本ページにて開示されます。
                    </Text>

                    <Text mt={5} as={'div'}>
                        制定日：2022年10月
                        {/* 改訂日： */}
                    </Text>
                </Box>

                <Box w='800px' maxW='100%' p={3} mb={5}>
                    <Heading fontSize='lg'>LangLabJP Privacy Policy</Heading>

                    <Text mt={5} as={'div'}>
                        The following describes the privacy policy and disclaimer for this site.
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>Purpose of Use of Personal Information</Heading>
                        This site may ask you to enter your name, e-mail address, and other personal information when you make an inquiry or post a comment.<br/>
                        Personal information obtained will be used only for necessary communications and will not be used for any other purposes.
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>Disclosure of Personal Information to Third Parties</Heading>
                        Personal information obtained will be managed appropriately and will not be disclosed to third parties except in the following cases.<br/>
                        ・When the consent of the person in question has been obtained<br/>
                        ・When disclosure is required by law
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>Use of Cookies</Heading>
                        This site uses cookies for advertising delivery and access analysis.<br/>
                        Cookies identify browsers, but they remain anonymous in such a way that specific individuals cannot be identified.<br/>
                        If you do not wish to use cookies, you can set your browser to disable cookies.
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>About Advertising Distribution Services</Heading>
                        This site uses a third-party advertising service to serve ads.<br/>
                        Third-party distribution providers use cookies to display personalized advertisements based on user interests.<br/>
                        Please contact the ad-serving provider directly for information about ad settings.
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>About Access Analysis Tools</Heading>
                        This site uses Google Analytics to analyze access information.<br/>
                        Cookies are used to analyze access information. The collection of access information can be rejected by disabling cookies.<br/>
                        Please refer to the following URL for more information on Google's data collection and processing mechanism.<br/>
                        https://policies.google.com/technologies/partner-sites?hl=ja
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>Disclaimer</Heading>
                        This site assumes no responsibility for any damages caused by the posted contents.<br/>
                        Although we try to provide as much accurate information as possible in each content, we do not guarantee the accuracy or safety of the information.<br/>
                        We are also not responsible for the information and services provided on other sites linked to this site.
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>Copyrights</Heading>
                        The copyright of the contents of this site belongs to the operator (as a derivative work or compilation work), but there are no restrictions on its use, and it can be used freely.<br/>
                         (Except in some cases, please check the "Acknowledgement" at the bottom of each page.)<br/>
                        However, we prohibit the use of scraping to continuously retrieve large amounts of data, or any other actions that place a heavy load on the server.
                    </Text>

                    <Text mt={5} as={'div'}>
                        <Heading fontSize='md'>Changes to our Privacy Policy</Heading>
                        This site will comply with Japanese laws and regulations applicable to personal information, and will review and improve this privacy policy from time to time.<br/>
                        The revised and updated Privacy Policy will always be disclosed on this page.
                    </Text>

                    <Text mt={5} as={'div'}>
                        Enactment date：Oct 2022
                        {/* Revision date： */}
                    </Text>
                </Box>
            </Stack>
            
            <Footer />
        </>
    )
}