import {
    Box,
    Container,
    SimpleGrid,
    Stack,
    //Link,
    Text,
    useColorModeValue,
    Flex,
    Image,
    useBreakpointValue,
    Divider,
    Tag
} from '@chakra-ui/react';
import { Link } from '../functionalComponents/ChakraLinkAsReactRouter';

const ListHeader = ({ children }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

const WithTag = ({ children, tag }) => {
    return (
        <Stack direction={'row'} align={'center'} spacing={2}>
            {children}
            <Tag
                size={'sm'}
                bg={useColorModeValue('green.300', 'green.800')}
                ml={2}
                color={'white'}
            >
                {tag}
            </Tag>
        </Stack>
    )
};

export default function Footer() {
    let today = new Date();
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
        >
            <Divider />
            <Container as={Stack} maxW={'6xl'} px={4} pt={10} pb={12} >
                <SimpleGrid
                    templateColumns={{ sm: '2fr 3fr', md: '6fr 10fr 5fr' }}
                    spacing={8}
                >
                    <Stack align={'flex-start'}>
                        <ListHeader>About LangLabJP</ListHeader>
                        <Link 
                            //href={'/about'}
                            to={'/about'}
                        >
                            About
                        </Link>
                        <WithTag tag='Coming soon'>
                            <Link 
                                //href={'/contact'}
                                to={'/contact'}
                            >
                                Contact Us
                            </Link>
                        </WithTag>
                        <Link 
                            //href={'/privacypolicy'}
                            to={'/privacypolicy'}
                        >
                            Privacy Policy
                        </Link>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Services</ListHeader>
                        <Link 
                            //href={'/Jedict'}
                            to={'/Jedict'}
                        >
                            Japanese-English Dictionary
                        </Link>
                        <WithTag tag='Coming soon'>
                            <Link 
                                //href={'#'} 
                                to={'#'} 
                                onClick={e => e.preventDefault()}
                            >
                                English-Japanese Dictionary
                            </Link>
                        </WithTag>
                        <Link 
                            //href={'/Kdict'}
                            to={'/Kdict'}
                        >
                            Kanji Dictionary
                        </Link>
                        <Link 
                            //href={'/Furiganakun'}
                            to={'/Furiganakun'}
                        >
                            Furigana-kun
                        </Link>
                    </Stack>
                    <Stack spacing={2}>
                        <Box>
                            <Flex flex={{ base: 1 }} justify={{ base: 'left', md: 'start' }} to={'/'} as={Link} _hover={{textDecoration: 'none'}}>
                                <Image src='/logo512.png' w={8} h={8} alt='logo'/>
                                <Text
                                    textAlign={useBreakpointValue({ base: 'center', md: 'center' })}
                                    fontFamily={'heading'}
                                    fontWeight='600'
                                    my='auto'
                                    ml={2}
                                >
                                    <Text as='span' color='blue.400'>LangLab</Text><Text as='span' color='red.400' ml={0.5}>JP</Text>
                                </Text> 
                            </Flex>
                        </Box>
                        <Box>
                            <Text fontSize={'sm'}>
                                © 2022-{today.getFullYear()} LangLabJP, tim@lamblabo
                            </Text>
                            <Box mt={3} dangerouslySetInnerHTML={{
                                __html: `
                                    <a href="https://www.buymeacoffee.com/timlamblabo" target="_blank">
                                        <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" style="max-width: 230px;" />
                                    </a>
                                `
                            }}>
                            </Box>
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    );
}