import { useParams } from 'react-router-dom';
import { 
    Stack, 
    VStack, 
    Box 
} from '@chakra-ui/react';
import Head from '../functionalComponents/Head';
import JedictSearchbox from './JedictSearchbox';
import JedictResultsMain from './JedictResultsMain';
import JedictSentenceBreakdown from './JedictSentenceBreakdown'
import JedictRelatedSearches from './JedictRelatedSearches';
import JedictOtherDicts from './JedictOtherDicts';
import JedictAcknowledgement from './JedictAcknowledgement';
import { useEffect } from 'react';

export default function JedictResults(){
    const params = useParams();

    useEffect(()=>{
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, 200);
    }, [params])

    return (
        <>
            <Head 
                url={window.location.href} 
                title={`Meaning of ${params.searchQuery} | Japanese-English Dictionary (和英辞書) by LangLabJP`}
                description={`Meaning of ${params.searchQuery} | Japanese-English Dictionary (和英辞書) by LangLabJP`}
            />
            <VStack mt='5px' spacing='24px'>
                <Box w='1100px' maxW='100%' px={2}>
                    <JedictSearchbox query={params.searchQuery} />
                </Box>
                <Box w='1100px' maxW='100%' px={2}>
                    <Stack direction={['column', 'row']} spacing='24px' maxW='100%'>
                        <Box w='700%' maxW='100%'>
                            <JedictResultsMain query={params.searchQuery}/>
                        </Box>
                        <Box w='300%' maxW='100%'>
                            <JedictSentenceBreakdown query={params.searchQuery}/>
                            <JedictRelatedSearches query={params.searchQuery}/>
                            <JedictOtherDicts query={params.searchQuery}/>
                        </Box>
                    </Stack>
                </Box>
                <Box w='100%'>
                    <JedictAcknowledgement />
                </Box>
            </VStack>
        </>
    )
}