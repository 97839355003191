import Head from '../functionalComponents/Head';
import Topbar from './Topbar';
import Footer from './Footer';
import {
    VStack, 
    Box, 
    Heading, 
} from '@chakra-ui/react';
import { Link } from '../functionalComponents/ChakraLinkAsReactRouter';
import { KanjiData } from '../functionalComponents/KanjiData';

export default function ListGakunenbetsuKanji(prop){
    const joyoKanjis = KanjiData();

    const kanjisWithStroke = {};
    [...Array(30)].map((v,k) => k+1).forEach(i => kanjisWithStroke[i] = joyoKanjis.filter(kanji => kanji.grade === prop.grade && kanji.stroke === i));

    window.scrollTo({ top: 0, behavior: 'smooth' });
    
    return (
        <>
            <Head 
                url={window.location.href} 
                title={`Kanji taught in grade ${prop.grade} | LangLabJP`}
                description={`Kanji taught in grade ${prop.grade} | LangLabJP`}
            />
            <Topbar />
            <VStack m='5px'>
                <Box w='1100px' maxW='100%'>
                    <Heading as='h1' size={'md'} color={'blue.700'} px={2} mt={2} noOfLines={2}>
                        Kanji taught in grade {prop.grade}
                    </Heading>
                </Box>
                <Box w='1100px' maxW='100%'>
                    {[...Array(30)].map((v,k) => k+1).map(i => {
                        return (
                            kanjisWithStroke[i]?.length > 0 ? 
                                <Box key={i} m={2}>
                                    <Heading fontSize='xl' mt={4} mb={2} p={2} borderWidth='1px' borderRadius='15px' borderColor='blue.50' backgroundColor='blue.50' color='blue.700'>{i} Strokes</Heading> 
                                    {kanjisWithStroke[i]?.map(kanji => <Link to={`/Kanji/${kanji.kanji}`} key={kanji.kanji} p={1} m={2} fontSize='lg'>{kanji.kanji}</Link>)}
                                </Box>
                                : ''
                        )
                    })}
                </Box>
            </VStack>
            <Footer />
        </>
    )
}