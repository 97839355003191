import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import Home from './uiComponents/Home';
import About from './uiComponents/About';
import Jedict from './uiComponents/Jedict';
import JedictIndex from './uiComponents/JedictIndex';
import JedictResults from './uiComponents/JedictResults';
import Kdict from './uiComponents/Kdict';
import KdictIndex from './uiComponents/KdictIndex';
import KdictResults from './uiComponents/KdictResults';
import Kanji from './uiComponents/Kanji';
import ListJoyoKanji from './uiComponents/ListJoyoKanji';
import ListGakunenbetsuKanji from './uiComponents/ListGakunenbetsuKanji';
import ListJlptKanji from './uiComponents/ListJlptKanji';
import Furiganakun from './uiComponents/Furiganakun';
import Contact from './uiComponents/Contact';
import PrivacyPolicy from './uiComponents/PrivacyPolicy';
import Page404  from './uiComponents/Page404';

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='jedict' element={<Jedict />}>
            <Route index element={<JedictIndex />} />
            <Route path=':searchQuery' element={<JedictResults />} />
          </Route>
          <Route path='kdict' element={<Kdict />}>
            <Route index element={<KdictIndex />} />
            <Route path=':searchQuery' element={<KdictResults />} />
          </Route>
          <Route path='kanji'>
            <Route index element={<Page404 />} />
            <Route path=':kanji' element={<Kanji />} />
          </Route>
          <Route path='list'>
            <Route index element={<Page404 />} />
            <Route path='joyokanji' element={<ListJoyoKanji />} />
            <Route path='gakunenbetsukanji'>
              <Route index element={<Page404 />} />
              <Route path='1' element={<ListGakunenbetsuKanji grade={1} />} />
              <Route path='2' element={<ListGakunenbetsuKanji grade={2} />} />
              <Route path='3' element={<ListGakunenbetsuKanji grade={3} />} />
              <Route path='4' element={<ListGakunenbetsuKanji grade={4} />} />
              <Route path='5' element={<ListGakunenbetsuKanji grade={5} />} />
              <Route path='6' element={<ListGakunenbetsuKanji grade={6} />} />
            </Route>
            <Route path='jlptkanji'>
              <Route index element={<Page404 />} />
              <Route path='n1' element={<ListJlptKanji jlpt='N1' />} />
              <Route path='n2' element={<ListJlptKanji jlpt='N2' />} />
              <Route path='n3' element={<ListJlptKanji jlpt='N3' />} />
              <Route path='n4' element={<ListJlptKanji jlpt='N4' />} />
              <Route path='n5' element={<ListJlptKanji jlpt='N5' />} />
            </Route>
          </Route>
          <Route path='furiganakun' element={<Furiganakun />} />
          <Route path='contact' element={<Contact />} />
          <Route path='privacypolicy' element={<PrivacyPolicy />} />
          <Route path='*' element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
