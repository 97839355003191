import React from 'react';
import {
    Box,
    // IconButton,
    // useBreakpointValue,
    Stack,
    Heading,
    Text,
    Container,
} from '@chakra-ui/react';
// Here we have used react-icons package for the icons
// import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
// And react-slick as our Carousel Lib
import Slider from 'react-slick';

// Settings for the slider
const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
};

export default function HomeCarousels() {
    // As we have used custom buttons, we need a reference variable to
    // change the state
    // const [slider, setSlider] = React.useState(null);

    // These are the breakpoints which changes the position of the
    // buttons as the screen size changes
    // const top = useBreakpointValue({ base: '90%', md: '50%' });
    // const side = useBreakpointValue({ base: '30%', md: '40px' });

    // This list contains all the data for carousels
    // This can be static or loaded from a server
    const cards = [
        {
            title: 'LangLabJP',
            text: `Providing high-quality digital learning materials for Japanese language learners. Using modern technologies, mobile-friendly, easy-to-use. Learn with fun!`,
            image: '/cover_fujisan.jpg',
        },
        {
            title: 'Dictionaries',
            text: `Our dictionaries are more than just a dictionary. You can not only look up words / sentence, but also search for related concepts as well as knowledge about Japan.`,
            image: '/cover_books.jpg',
        },
        {
            title: 'Tools',
            text: `Many helpful tools that can help you learn Japanese language better. Have a try! Much more coming soon.`,
            image: '/cover_devices.jpg',
        }
    ];

    return (
        <Box
            position={'relative'}
            height={{ base: '400px', md: '500px', lg: '600px' }}
            width={'full'}
            overflow={'hidden'}
        >
            {/* CSS files for react-slick */}
            <link
                rel='stylesheet'
                type='text/css'
                charSet='UTF-8'
                // href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
                href='/css/slick.min.css'
            />
            <link
                rel='stylesheet'
                type='text/css'
                // href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
                href='/css/slick-theme.min.css'
            />
            {/* Left Icon */}
            {/* <IconButton
                aria-label='left-arrow'
                variant='ghost'
                position='absolute'
                left={side}
                top={top}
                transform={'translate(0%, -50%)'}
                zIndex={2}
                onClick={() => slider?.slickPrev()}
            >
                <BiLeftArrowAlt size='40px'/>
            </IconButton> */}
            {/* Right Icon */}
            {/* <IconButton
                aria-label='right-arrow'
                variant='ghost'
                position='absolute'
                right={side}
                top={top}
                transform={'translate(0%, -50%)'}
                zIndex={2}
                onClick={() => slider?.slickNext()}
            >
                <BiRightArrowAlt size='40px' />
            </IconButton> */}
            {/* Slider */}
            <Slider {...settings} /*ref={(slider) => setSlider(slider)}*/>
                {cards.map((card, index) => (
                    <Box
                        key={index}
                        height={'2xl'}
                        position='relative'
                        backgroundPosition='center'
                        backgroundRepeat='no-repeat'
                        backgroundSize='cover'
                        backgroundImage={`url(${card.image})`}
                    >
                        {/* This is the block you need to change, to customize the caption */}
                        <Container size='container.lg' height={{ base: '400px', md: '500px', lg: '600px' }} position='relative'>
                            <Stack
                                spacing={6}
                                w={'90%'}
                                maxW={'md'}
                                position='absolute'
                                top='50%'
                                transform='translate(0, -50%)'
                            >
                                <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }} color='white'>
                                    {card.title}
                                </Heading>
                                <Text fontSize={{ base: 'md', lg: 'lg' }} color='white'>
                                    {card.text}
                                </Text>
                            </Stack>
                        </Container>
                    </Box>
                ))}
            </Slider>
        </Box>
    );
}