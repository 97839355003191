import { 
    useState, 
    useEffect 
} from 'react';
import {
    VStack,
    Box,
    Heading,
    Divider,
    Center,
    Spinner,
    Link,
    List,
    ListItem,
    ListIcon
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons'


export default function JedictOtherDicts(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [query, setQuery] = useState('');

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 300);
        setQuery(props.query);
    }, [props.query]);

    return (
        <VStack align='stretch' spacing='10px'>
            <Box>
                <Heading as='h1' size='sm' noOfLines={1}>
                    Search on the internet
                </Heading>
                <Divider />
            </Box>
            {isLoading ? (
                <>
                    <Center>
                        <Spinner
                            thickness='1.5px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='sm'
                        />
                    </Center>
                </>
            ) : (
                <Box>
                    <List spacing={2}>
                        <ListItem>
                            <Link href={`https://www.google.com/search?q=${query}`} color={'blue.600'} isExternal>
                                <ListIcon as={CheckIcon} color='blue.500' />
                                Search '{query.length > 10 ? `${query.substring(0, 10)}...` : query}' on Google
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href={`https://duckduckgo.com/?q=${query}&kl=jp-jp`} color={'blue.600'} isExternal>
                                <ListIcon as={CheckIcon} color='blue.500' />
                                Search '{query.length > 10 ? `${query.substring(0, 10)}...` : query}' on DuckDuckGo
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href={`https://www.bing.com/search?q=${query}`} color={'blue.600'} isExternal>
                                <ListIcon as={CheckIcon} color='blue.500' />
                                Search '{query.length > 10 ? `${query.substring(0, 10)}...` : query}' on Bing
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href={`https://search.yahoo.co.jp/search?p=${query}`} color={'blue.600'} isExternal>
                                <ListIcon as={CheckIcon} color='blue.500' />
                                Search '{query.length > 10 ? `${query.substring(0, 10)}...` : query}' on Yahoo!JAPAN
                            </Link>
                        </ListItem>
                    </List>
                </Box>
            )}
        </VStack>
    );
}
