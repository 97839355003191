import {
    Box,
    Center,
    Container,
    Stack,
    Heading,
    Text,
    useColorModeValue,
    Link
} from '@chakra-ui/react';

export default function HomeAcknowledgement() {
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
            w='100%'
            mt={50}
        >
            <Center>
                <Heading as='h2' size='sm' pt={4} pl={4}>Acknowledgement</Heading>
                
            </Center>
            <Center>
                <Heading as='h3' size='xs' pt={2} fontWeight='400'>Cover images using the following works:</Heading>
            </Center>
            <Container
                as={Stack}
                maxW={'6xl'}
                py={2}
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                justify={{ base: 'center', md: 'space-between' }}
                align={{ base: 'start', md: 'start' }}
            >
                <Box>
                    <Text as='p'>
                        『日没時​の​富士山​と​河口湖​、​秋​の​山梨県​の​富士山​。』 by <Link href='https://jp.freepik.com/free-photo/fuji-mountain-and-kawaguchiko-lake-at-sunset-autumn-seasons-fuji-mountain-at-yamanachi-in-japan_10824606.htm#query=fuji&position=4&from_view=keyword'>tawatchai07</Link> from <Link href='https://jp.freepik.com'>Freepik</Link>
                    </Text>
                </Box>
                <Box>
                    <Text as='p'>
                        『開いている​教科書​の​ある​図書館​で​予約する』 by <Link href='https://jp.freepik.com/free-photo/book-in-library-with-open-textbook_3737798.htm#query=books&position=0&from_view=search'>jcomp</Link> from <Link href='https://jp.freepik.com'>Freepik</Link>
                    </Text>
                </Box>
                <Box>
                    <Text as='p'>
                        『未来的​な​ユーザーインターフェイス​要素​ベクトル​セット​。』 by <Link href='https://jp.freepik.com/free-vector/futuristic-user-interface-elements-vector-set_10601013.htm#query=%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB&position=31&from_view=search'>macrovector</Link> from <Link href='https://jp.freepik.com'>Freepik</Link>
                    </Text>
                </Box>
            </Container>
        </Box>
    );
}