import { useEffect } from 'react';
import { 
    VStack, 
    Box, 
    SimpleGrid, 
    Icon, 
    Text, 
    Stack, 
    Flex, 
    Heading, 
    Divider, 
    Center, 
    TableContainer, 
    Table, 
    // TableCaption, 
    // Thead, 
    Tr, 
    Th, 
    Tbody, 
    Td, 
    // Tfoot 
} from '@chakra-ui/react';
import Head from '../functionalComponents/Head';
import Topbar from './Topbar';
import { FcLike, FcSalesPerformance, FcSmartphoneTablet } from 'react-icons/fc';
import Footer from './Footer';

function TopicHeading({children}) {
    return (
        <>
            <Heading as='h1' size='lg' mt={6} mb={1}>{children}</Heading>
            <Divider mb={2} />
        </>
    )
}

function AboutLLJP({children}) {
    return <Box fontSize='xl' color={'gray.600'}>{children}</Box>
}

const Feature = ({ title, text, icon }) => {
    return (
        <Stack>
            <Flex
                w={16}
                h={16}
                align={'center'}
                justify={'center'}
                color={'white'}
                rounded={'full'}
                bg={'gray.100'}
                mb={1}
            >
                {icon}
            </Flex>
            <Text fontWeight={600}>{title}</Text>
            <Text color={'gray.600'}>{text}</Text>
        </Stack>
    );
};

function OurPurpose() {
    return (
        <Box p={4}>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                <Feature
                    icon={<Icon as={FcLike} w={10} h={10} />}
                    title={'Like Japan'}
                    text={
                        'We like Japan. So we hope you will enjoy learning Japanese language with our services / products.'
                    }
                />
                <Feature
                    icon={<Icon as={FcSalesPerformance} w={10} h={10} />}
                    title={'Education should be accessible to everyone'}
                    text={
                        'We offer high quality services / products for free or at an affordable price.'
                    }
                />
                <Feature
                    icon={<Icon as={FcSmartphoneTablet} w={10} h={10} />}
                    title={'Learning is not limited to the classroom'}
                    text={
                        'We provide mobile-friendly, easy-to-use websites using modern technologies. Study everwhere!'
                    }
                />
            </SimpleGrid>
        </Box>
    );
}

function TechStack() {
    return (
        <Center>
            <TableContainer w='600px' maxW='100%'>
                <Table variant='simple' colorScheme='blue' color={'gray.600'} align='left'>
                    <Tbody>
                        <Tr>
                            <Th>Frontend frameworks</Th>
                            <Td>ReactJS</Td>
                        </Tr>
                        <Tr>
                            <Th>UI libraries</Th>
                            <Td>Chakra UI</Td>
                        </Tr>
                        <Tr>
                            <Th>Backend frameworks</Th>
                            <Td>Node.js <br/> 
                                ExpressJS <br/> 
                                Flask (Python) <br/>
                                Google Apps Script
                            </Td>
                        </Tr>
                        <Tr>
                            <Th>Analysis</Th>
                            <Td>Google Analytics</Td>
                        </Tr>
                        <Tr>
                            <Th>NLP libraries</Th>
                            <Td>Sudachi <br/> 
                                chiVe (Sudachi Vector)
                            </Td>
                        </Tr>
                        <Tr>
                            <Th>Database</Th>
                            <Td>MySQL</Td>
                        </Tr>
                        <Tr>
                            <Th>Version controll</Th>
                            <Td>Github</Td>
                        </Tr>
                        <Tr>
                            <Th>Other open source works</Th>
                            <Td>JMDict <br/> 
                                Wikidata <br/> 
                                kanjiapi<br/> 
                                EDICT<br/> 
                                KANJIDIC<br/> 
                                kanjiVG
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </Center>
    )
}

export default function About(){
    useEffect(()=>{
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, 200);
    }, [])

    return (
        <>
            <Head 
                url={window.location.href} 
                title={`About | LangLabJP`}
                description={`About | LangLabJP`}
            />
            <Topbar />
            <VStack mt='5px' mb={8} spacing='24px'>
                <Box w='1100px' maxW='100%' px={2}>
                    <TopicHeading>About LangLabJP</TopicHeading>
                    <AboutLLJP>LangLabJP is a side-project developed by tim@lamblabo.</AboutLLJP>
                </Box>
                <Box w='1100px' maxW='100%' px={2}>
                    <TopicHeading>Our Purpose</TopicHeading>
                    <OurPurpose />
                </Box>
                <Box w='1100px' maxW='100%' px={2}>
                    <TopicHeading>Tech stack / Data source</TopicHeading>
                    <TechStack />
                </Box>
            </VStack>
            <Footer />
        </>
    )
}